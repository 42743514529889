import React from 'react';

import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-rennes-black.svg';
import '../../styles/header.scss';

export default function Header() {

  return (
    <Link to='/'>
      <header>
        <div className="home-header">

          <img src={logo} alt="logo-rennes"/>
          <div>
            <h1 className="bold">ÉLECTIONS</h1>
            <span>Les résultats électoraux à Rennes <br/>{'&'} Rennes Métropole</span>
          </div>
    
        </div>
        <div className="bar"></div>
      </header>
    </Link>
  )
}
