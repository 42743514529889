import React from 'react';
import "../../styles/participation.scss";
import { useSpring, animated } from 'react-spring';
import LegendParticipation from '../Partials/LegendParticipation/LegendParticipation';

export default function Particitpation({ results , selectedCenterData , selectedBureauData , selectedCityData , isCanton , allDatas, mapIsActive , data }) {

    const nb_exprimes = useSpring({ number: results?.nb_exprimes? parseFloat(results.nb_exprimes) : 0 , from: { number: 0 } });
    const nb_blanc = useSpring({ number: results?.nb_blanc ? parseFloat(results.nb_blanc) : 0 , from: { number: 0 } });
    const nb_nuls = useSpring({ number: results?.nb_nuls ? parseFloat(results.nb_nuls) : 0 , from: { number: 0 } });
    const nb_inscrits = useSpring({ number: results?.nb_inscrits ? parseFloat(results.nb_inscrits) : 0  , from: { number: 0 } });
    const pourcentage_participation = useSpring({ number: results?.pourcentage_participation ? parseFloat(results.pourcentage_participation) : 0  , from: { number: 0 } });

    function renderTitleLegend() {
      if (selectedCenterData && selectedCenterData.data) {
        if (selectedBureauData && selectedBureauData.data) {
          return <h5 className="slide-in-right">{selectedCenterData.data.nom_lieu}<br/>Bureau {selectedBureauData.data.numero_lieu}</h5>;
        } else {
          return <h5 id="title-2" className="slide-in-right">{isCanton ? `Canton ${selectedCenterData.data.numero_canton}` : selectedCenterData.data.nom_lieu}</h5>
        }
      } else if (!selectedCenterData && selectedBureauData && selectedBureauData.data) {
        if (isCanton) {
          return <h5 id="title-2" className="slide-in-right">Canton {selectedBureauData.data.numero_canton}</h5>
        } else {
          return <h5 id="title-2" className="slide-in-right">Bureau {selectedBureauData.data.numero_lieu}</h5>
        }
      } else if (!selectedCenterData &&! selectedBureauData && selectedCityData?.data) {
        return <h5 id="title-2" className="slide-in-right">{selectedCityData?.data?.libelle_commune}</h5>
  
      } else {
        return null;
      }
    }

    return (
      <div className="participation">
        <LegendParticipation
          allDatas={allDatas}
          mapIsActive={mapIsActive}
          data={data} />
        {results 
          && <>
          <div className="hide-mobile">
            {renderTitleLegend()}
          </div>
          <div className="pariciptation-range">
            <h3><animated.span>{pourcentage_participation.number.interpolate(x => x.toFixed(2))}</animated.span> % </h3>
            <div className="range-bar"></div>
          </div>
          <div>
            <p>
              <animated.span>{nb_inscrits.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> inscrits</span>
            </p>
            <p>
              <animated.span>{nb_exprimes.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> exprimés</span>
            </p>
            {parseFloat(results?.nb_blanc) > 0 &&
              <p>
                <animated.span>{nb_blanc.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> blancs</span>
              </p>
            }
            <p>
              <animated.span>{nb_nuls.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> nuls</span>
            </p>
          </div>
          </>
        }   
      </div>
    )
};
