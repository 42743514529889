import React , { useState , useEffect } from 'react';

export default function Share({ shareIsActive , setShareIsActive }) {

   const [ isCopied , setIsCopied ] = useState(false);

   useEffect(() => {
    if (!shareIsActive) setIsCopied(false);
   },[shareIsActive]);

   var iframe = `
    <iframe src="${window.location.href}" style="width: 100%; height: 100%; min-height: 880px" frameborder="0" allowfullscreen>
    </iframe>
    `;

   function copy() {
    var copyText = document.getElementById("iframe-value");
    copyText.select();
    setIsCopied(true)
    document.execCommand("copy");
  }

  return (
    <div className={`share-modale ${shareIsActive ? "" : "not-active"}`}>
      <h2 className="bold">
        Intégrer les résultats
      </h2>
      <div className="bar-share">
        <div className="bar"></div>
      </div>
      <textarea value={iframe} id="iframe-value" readOnly />
      {isCopied && <p>Ce code a été copié, vous pouvez maintenant l'intégrer à votre site</p>}
      <div className="btn-container">
        <button
          className="btn dark"
          onClick={() => setShareIsActive(false)} >
          Fermer
        </button>
        <button
          className="btn dark"
          onClick={() => copy()} >
          Copier le code
        </button>
      </div>
    </div>
  )
}
