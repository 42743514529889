import React, { useEffect , useState } from 'react';
import moreImg from '../../../assets/images/more.svg';
import { getCandidatsArray } from '../../../utils';
import CandidatRow from '../CandidatRow/CandidatRow';
import styles from "./Candidats.module.scss";

export default function Candidats({
  results,
  setActivePanel = null,
  isMobile = false
}) {
  const [ candidatsDatas , setCandidatsDatas ] = useState([]);

  useEffect(() => {
    if (results) {
      setCandidatsDatas(getCandidatsArray(results));
    }
  }, [results]);
  const max = candidatsDatas.sort((a, b) => a.nb_voix - b.nb_voix).reverse()[0]?.pourcentage;
  let location = results?.nom_lieu || results?.libelle_commune;
  if (results?.niveau_detail === "bu") {
    location = `Bureau ${results.numero_lieu}`;
  }
  return (
    <div className={styles.container}>
      {isMobile && results?.candidat_1 &&
        <div className={styles.location}>
          <h2>{location}</h2>
          <button
            onClick={() => setActivePanel(results)}
          >
            <span>En voir plus</span>
            <img src={moreImg} alt="plus-infos" />
          </button>
        </div>
      }
      <div className={styles.list}>
        {candidatsDatas.map((item, i) => {
          if (isMobile && i > 1) {
            return null;
          }
          if (item.name) {
            return ( <CandidatRow key={i} isMobile={isMobile} candidat={item} max={max} />)
          }
          return null;
        })}
      </div>
    </div>
  )
}
