import React from 'react';
import mapIcon from '../../../assets/images/map.svg';
import listIcon from '../../../assets/images/list.svg';
import mapIconActive from '../../../assets/images/map-active.svg';
import listIconActive from '../../../assets/images/list-active.svg';
import styles from './DataViewToogle.module.scss';


export default function DataViewToogle({ setMapIsActive , mapIsActive }) {
  return (
    <div className={styles.container}>
      <button className={mapIsActive ? styles.active : ""} onClick={() => setMapIsActive(true)}>
        <p>Mode carte</p>
        <img src={!mapIsActive ? mapIcon : mapIconActive } alt="icon-map"/>
      </button>
      <button className={!mapIsActive ? styles.active : ""} onClick={() => setMapIsActive(false)}>
        <p>Mode liste</p>
        <img src={mapIsActive ? listIcon : listIconActive } alt="icon-lit"/>
      </button>
    </div>
  )
}
