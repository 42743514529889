import React from 'react';
import { scaleLinear } from "d3-scale";
import { useSpring, animated } from 'react-spring';
import styles from './CandidatRow.module.scss';

export default function CandidatRow({ candidat, max , isMobile }) {
  const barWidth = isMobile ? 90 : 120;
  var scale = scaleLinear()
  .domain([0, max])
  .range([0,  barWidth ]);

  var pourcentageValue = candidat.pourcentage ? parseFloat(candidat.pourcentage) : 0 ;
  var voixValue = candidat.nb_voix ? parseFloat(candidat.nb_voix) : 0 ;

  const barSize = useSpring({ number: scale(pourcentageValue)  , from: { number: 0 } });
  const pourcentage = useSpring({ number: pourcentageValue , from: { number: 0 } });
  const nb_voix = useSpring({ number: voixValue , from: { number: 0 } });

  return (
    <div className={styles.container}>
      <div className={styles["bar-chart"]}>
        <span className={styles["bar-legend"]}>
          <animated.span>{pourcentage.number.interpolate(x => x.toFixed(2))}</animated.span> %
        </span>
        <div className={styles.bar} style={{ width: barWidth }}>
          <animated.div className={styles["range-bar"]} style={{ width : barSize.number }}></animated.div>
        </div>
        <span style={{ fontSize: 13 , marginTop: 5  ,  display: isMobile ? "none": "block"}}>
          <animated.span>{nb_voix.number.interpolate(x => x.toFixed(0))}</animated.span> voix
        </span>
      </div>
      <div className={styles.infos}>
        <span className={styles.name}>{candidat.name}</span>
      </div>
    </div>
  )
}
