export const selectStyle = {
  control: (provided, state) => ({
    ...provided,
    borderRadius : "3px",
    border : "solid 1px #CDCDCD",
    outline : 'none',
    zIndex: 310,
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
       border: state.isFocused ? "solid 1px #B2B2B2" : "solid 1px #B2B2B2"
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 311,
    borderRadius : "3px",
    border : "solid 1px #B2B2B2",
    overflow : "hidden",
    boxShadow: state.isFocused ? 0 : 0,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#FF8C7E" : 'ransparent',
    padding : "5px 12px",
    fontSize : "12px"
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize : "14px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize : "14px"
  })
}