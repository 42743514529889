import React , { useState ,  useEffect } from 'react';
import { Switch, Route , useHistory } from 'react-router-dom';
import useDimensions from 'react-use-dimensions';

import ElectionDataPage from './Components/Pages/ElectionDataPage';
// import ElectionDataCantonsPage from './Components/Pages/ElectionDataCantonsPage';
// import ElectionLivePage from './Components/Pages/ElectionLivePage';
// import ElectionPage from './Components/Pages/ElectionPage';
import LegalNoticePage from './Components/Pages/LegalNoticePage';
import Accessibility from './Components/Pages/Accessibility';
import HomePage from './Components/Pages/HomePage';

import Footer from './Components/Partials/Footer';
import CookiesBar from './Components/Partials/CookiesBar';

import texts from './assets/texts/electionsDescriptions.json'

const routes = [
  {
    url: "/",
    name: "Accueil",
    disabled: false
  },
  {
    url: "/legislatives",
    name: "Législatives",
    data: true,
    link: {
      title: "Législatives",
      years: "2012 à 2024"
    },
    text: texts.legislatives,
    map: "bureaux",
    isHidden: false,
  },
  {
    url: "/presidentielles",
    name: "Présidentielles",
    data: true,
    link: {
      title: "Présidentielles",
      years: "de 2007 à 2022"
    },
    text: texts.presidentielles,
    map: "bureaux",
    isHidden: false,
  },
  {
    url: "/municipales",
    name: "Municipales",
    data: true,
    link: {
      title: "Municipales",
      years: "2014 à 2020"
    },
    text: texts.municipales,
    map: "bureaux",
    isHidden: false,
  },
  {
    url: "/presidentielles",
    name: "Présidentielles",
    data: true,
    link: {
      title: "Départementales",
      years: "2014 à 2022"
    },
    text: texts.presidentielles,
    map: "bureaux",
    isHidden: true,
  },
  {
    url: "/regionales",
    name: "Régionales",
    data: true,
    link: {
      title: "Régionales",
      years: "2015 à 2021"
    },
    text: texts.regionales,
    map: "bureaux",
    isHidden: false,
  },
  {
    url: "/europeennes",
    name: "Européennes",
    data: true,
    link: {
      title: "Européennes",
      years: "2014 à 2019"
    },
    text: texts.europeennes,
    map: "bureaux",
    isHidden: false,
  },
]

function App() {

  const [ activePage , setActivePage ] = useState("Municipales");
  const [ appRef, appSize ] = useDimensions();
  // const [ displayNavMobile , setDisplayNavMobile ] = useState(false);
  const [ zoomOnBg , setZoomOnBg ] = useState("init-scale");
  const [ cookiesBarIsActive , setCookiesBarIsActive ] = useState(false);

  var history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("cookie") === "ok") {
      setCookiesBarIsActive(false);
    } else {
      setCookiesBarIsActive(true);
    };
  },[])

  useEffect(() => {
    document.body.scrollTop = 0;
    
  },[history.location.pathname])

  function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }

  return (
    <>
      {/* {isInIframe() === false &&
        <Nav
          links={routes.filter(route => !route.data)}
          activePage={activePage}
          setActivePage={setActivePage}
          displayNavMobile={displayNavMobile}
          setDisplayNavMobile={setDisplayNavMobile}
        />
      } */}
      <CookiesBar
        cookiesBarIsActive={cookiesBarIsActive}
        setCookiesBarIsActive={setCookiesBarIsActive}
        />
        <div ref={appRef} id="app" className={`main-container`}>
        <Switch>

          <Route path={"/accessibility"} exact render={() => <Accessibility
              name={"accessibility"}
              activePage={activePage}
              setActivePage={setActivePage}
              setZoomOnBg={setZoomOnBg}
              // setDisplayNavMobile={setDisplayNavMobile}
              zoomOnBg={zoomOnBg}
              />}
            />

          <Route path={"/legal-notice"} exact render={() => <LegalNoticePage
            name={"legal-notice"}
            activePage={activePage}
            setActivePage={setActivePage}
            setZoomOnBg={setZoomOnBg}
            // setDisplayNavMobile={setDisplayNavMobile}
            zoomOnBg={zoomOnBg}
            />}
          />

          <Route path={"/"} exact render={() => <HomePage
            routes={routes.filter(route => !route.data)}
            name={"Accueil"}
            setActivePage={setActivePage}
            setZoomOnBg={setZoomOnBg}
            links={routes.filter(route => route.data)}
            zoomOnBg={zoomOnBg}
            activePage={activePage}
            // setDisplayNavMobile={setDisplayNavMobile}
            />}
          />

          <Route path={"/direct/:election"} exact render={() => 
            <ElectionDataPage
              setActivePage={setActivePage}
              name={"Législatives en direct"}
              appSize={appSize}
              text={texts.legislatives}
              backUrl={"/"}
              isInIframe={isInIframe}
              setZoomOnBg={setZoomOnBg}
              zoomOnBg={zoomOnBg}
              isDirect={true}
              />}
            />
          
          {routes.filter(route => route.map === "bureaux").map((route , i ) =>
            <Route key={i} path={route.url} exact render={() => 
              <ElectionDataPage
                setActivePage={setActivePage}
                name={route.name}
                appSize={appSize}
                text={route.text}
                backUrl={"/"}
                isInIframe={isInIframe}
                setZoomOnBg={setZoomOnBg}
                zoomOnBg={zoomOnBg}
                />}
              />
          )}

          {/* {routes.filter(route => route.map === "cantons").map((route , i ) =>
            <Route key={i} path={route.url + "-cantons-data"} exact render={() => 
              <ElectionDataCantonsPage
                setActivePage={setActivePage}
                name={route.name}
                geoData={geoData}
                appSize={appSize}
                backUrl={route.url}
                isInIframe={isInIframe}
                setZoomOnBg={setZoomOnBg}
                zoomOnBg={zoomOnBg}
                data={route.data}/>}
                />
          )} */}
          
          {/* {routes.filter(route => !route.data).map((route , i ) =>
            <Route
              key={i * 10 }
              path={route.url}
              exact
              render={() => 
                <ElectionPage
                  text={route.text}
                  setActivePage={setActivePage}
                  setZoomOnBg={setZoomOnBg}
                  zoomOnBg={zoomOnBg}
                  url={route.url === '/departementales' ? route.url + "-cantons-data" : route.url + "-data"}
                  disabled={route.disabled}
                  name={route.name} 
                  activePage={activePage}
                  // setDisplayNavMobile={setDisplayNavMobile}
                />}
              />
          )} */}
        </Switch>
        {isInIframe() === false &&
          <Footer />
        }
      </div>          
    </>
  )
}

export default App;
