import React , { useState , useEffect } from 'react';
import * as _ from 'lodash';
import * as d3 from 'd3';
import styles from './LegendParticipation.module.scss'

export default function LegendParticipation({ allDatas, mapIsActive = true }) {
  const [ legend, setlegend] = useState();

  useEffect(() => {
    if (allDatas?.length > 0) {
      var minParticipation = _.minBy(allDatas, function(o) { return o.fields.pourcentage_participation; }).fields.pourcentage_participation || 0;
      var maxParticipation = _.maxBy(allDatas, function(o) { return o.fields.pourcentage_participation; }).fields.pourcentage_participation || 0;
      
      var opacityByParticipation = d3.scaleLinear()
          .domain([ minParticipation , maxParticipation ])
          .range([ 0.1 , 1 ]);
    
      var participation = {
        min: minParticipation,
        minOpacity: opacityByParticipation(minParticipation),
        max: maxParticipation,
        maxOpacity: opacityByParticipation( maxParticipation)
      };
      if (maxParticipation > 0 ) {
        setlegend(participation);
      }
    }
  }, [allDatas]);

  return (
    <div className={styles.legend}>
      {mapIsActive && legend?.max &&
        <>
        <h5>Participation</h5>
          <div className={styles["legend-row"]}>
            <div className={styles["legend-color"]} style={{ opacity: 0.1 }}>
            </div>
            <div className={styles["legend-color"]} style={{ opacity: 0.25 }}>
            </div>
            <div className={styles["legend-color"]} style={{ opacity: 0.50 }}>
            </div>
            <div className={styles["legend-color"]} style={{ opacity: 0.75 }}>
            </div>
            <div className={styles["legend-color"]} style={{ opacity: .9 }}>
            </div>
          </div>
          <div className={`${styles["legend-row"]} ${styles["text"]}`}>
            <span>{Math.floor(legend.min / 10) * 10 } %</span>
            <span>{Math.round(legend.max /10 ) * 10 } %</span>
          </div>
        </>
      }
    </div>
  )
}
