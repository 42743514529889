import React, { useState , useEffect , useCallback , useRef } from 'react';
import axios from 'axios';
import { useHistory , useLocation } from 'react-router-dom';
import * as _ from 'lodash';

import TourSlider from '../Partials/TourSlider';
import Background from '../Partials/Background';

import DataViewToogle from '../Partials/DataViewToogle/DataViewToogle';
// import MapSwitch from '../Partials/MapSwitch/MapSwitch';

import { getResultsByLevel } from '../../utils';

import '../../styles/electionDataPage.scss';
import getElection from '../../actions';
import DataHeader from '../Partials/DataHeader/DataHeader';
import DataDesktop from '../Partials/DataDesktop/DataDesktop';
import DataMobile from '../Partials/DataMobile/DataMobile';

export default function ElectionDataPage({ setActivePage, name , text, setZoomOnBg , isInIframe, backUrl , appSize , zoomOnBg , isDirect }) {
  setActivePage(name);

  const history = useHistory();
  const location = useLocation();

  const [ sliderMarks , setSliderMarks ] = useState();
  const [ selectedElection , setSelectedElection ] = useState();
  const [ electionData , setElectionData ] = useState();
  const [ results , setResults ] = useState();
  const [ mapData , setMapData ] = useState();

  const [ selectedCityData , setSelectedCityData ] = useState();
  const [ selectedCenterData , setSelectedCenterData ] = useState();
  const [ selectedBureauData , setSelectedBureauData ] = useState();

  const [ centersList , setCentersList ] = useState();
  const [ bureauxList , setBureauList ] = useState();
  const [ citiesList , setCitiesList ] = useState();

  const [ mapIsActive , setMapIsActive ] = useState(true);
  const [ donutIsActive , setDonutIsActive ] = useState(false);
  const [ tourLabel , setTourLabel ] = useState();

  const [ mapKey , setMapKey ] = useState("2014");
  // const [ isCantons , setIsCantons ] = useState(false);
  const [ data, setData ] = useState();

  const isMobile = appSize && appSize.width <= 768

  const [ electionProgress , setElectionProgress ] = useState();

  var timer = useRef();

  // const apiUrlReg = "https://data.rennesmetropole.fr/api/records/1.0/search/?dataset=resultats-des-elections-regionales-2021-2eme-tour&rows=180&q=&facet=numero_tour&facet=niveau_detail&facet=nom_lieu&apikey=ccf3c49d416750e0f2fd7d3a849af096ab71cfc6c1427b8420c72814"
  // const apiUrlDep = "https://data.rennesmetropole.fr/api/records/1.0/search/?dataset=resultats-des-elections-departementales-2021-2eme-tour&rows=180&q=&facet=numero_tour&facet=niveau_detail&facet=nom_lieu&apikey=ccf3c49d416750e0f2fd7d3a849af096ab71cfc6c1427b8420c72814"
  // const apiUrlPre = "https://data.rennesmetropole.fr/api/records/1.0/search/?dataset=resultats_p22_2&rows=190&q=&facet=code_commune&facet=libelle_commune&facet=niveau_detail&facet=numero_lieu&apikey=ccf3c49d416750e0f2fd7d3a849af096ab71cfc6c1427b8420c72814"
  // const apiUrlLg = "https://data.rennesmetropole.fr/api/records/1.0/search/?dataset=resultats_rennes_l_24_1&rows=190&q=&facet=code_commune&facet=libelle_commune&facet=niveau_detail&facet=numero_lieu&apikey=ccf3c49d416750e0f2fd7d3a849af096ab71cfc6c1427b8420c72814"
  // const apiUrlLg = "https://data.rennesmetropole.fr/api/records/1.0/search/?dataset=resultats_direct_lg22_2&rows=190&q=&facet=code_commune&facet=libelle_commune&facet=niveau_detail&facet=numero_lieu&apikey=ccf3c49d416750e0f2fd7d3a849af096ab71cfc6c1427b8420c72814"
  const apiUrlLg = "https://data.rennesmetropole.fr/api/records/1.0/search/?dataset=resultat-des-elections-legislatives-2024-2eme-tour&rows=220&q=&facet=code_commune&facet=libelle_commune&facet=niveau_detail&facet=numero_lieu&apikey=ccf3c49d416750e0f2fd7d3a849af096ab71cfc6c1427b8420c72814"



  const fetchLiveResult = async () => {
    try {
      let url = apiUrlLg;
      if (url) return await axios.get(url)
    } catch (error) {
      console.error(error)
    }
  };

  const getLiveResult = async () => {
    const res = await fetchLiveResult();
    if (res && res.data && res.data.records) {
      const data = res.data.records.filter((d) => d.fields.niveau_detail !== 'ci');
      const geojson = [];
      data.forEach((r) => {
        r.fields.code_commune = parseInt(r.fields.code_commune);
        r.fields.numero_lieu = parseInt(r.fields.numero_lieu);
        r.fields.code_election = "LG24";
        r.fields.pourcentage_participation = parseFloat(r.fields.pourcentage_participation);
        geojson.push({
          type: 'Feature',
          geometry: r.fields.geo_shape,
          data: r.fields,
          id: r.recordid,
          properties: {
            geo_point: r.fields.geo_point,
          }
        });
      });
      let recordsData = _.cloneDeep(data);
      recordsData = recordsData.map((r) => {
        r.fields.code_election = "LG24";
        // if (r?.fields?.geo_shape) {
        //   delete r.fields.geo_shape;
        // }
        return { fields : r.fields };
      });

      const liveData = {
        records: recordsData,
        geojson: {type: 'FeatureCollection', features: geojson },
      }
      setSelectedElection({ code_election: "LG24" , numero_tour: 2 , year: 2024 })
      setData(liveData);
      setMapIsActive(true);
    }
  };


  const getData = useCallback(() => {
    setData(getElection(name));
  },[name])
  
  function stopFetch() {
    if (isDirect) {
      clearInterval(timer.current);
    }
  };

  useEffect(() => {
    if (isDirect) {
      getLiveResult();
      window.scrollTo(0,0);
      var fiveMin = 300000;
    // var tenSeconds = 10000
      timer.current = setInterval(getLiveResult, fiveMin );
    } else {
      getData();
    }
    return () => {
      stopFetch();
      setData();
    }
    /* eslint-disable-next-line*/
  }, []);

  let displayCities = false;
  if (name === "Présidentielles") {
    displayCities = true;
  }
  if (name === 'Législatives' && selectedElection?.year >= 2022) {
    displayCities = true;
  }

  if (isDirect && selectedElection?.year >= 2022) {
    displayCities = true;
  }

  //INIT_SLIDER
  useEffect(() => {
    window.scrollTo(0,0);
    var marks = [];
    if (data?.records.length > 0) {
      data.records.forEach(element => {
        var year = "20" + element.fields.code_election.slice(element.fields.code_election.length-2 , element.fields.code_election.length ) ;
        var tour = parseInt(element.fields.numero_tour) === 1 ? " - 1er tour" : " - 2ème tour";
        var election = {
          value: parseFloat(year + "." + element.fields.numero_tour),
          dataSet: {
            code_election: element.fields.code_election,
            numero_tour: element.fields.numero_tour,
            year: parseFloat(year)
          },
          label: year + tour,
        }
        marks.push(election);
      });

      marks = _.uniqBy(marks, 'label').sort((a, b) => a.value - b.value );;
      marks.map((item, i) => item.value = i)
      setSliderMarks(marks);
    }

  },[data]);

  //CHOOSE ELECTION
  useEffect(() => {
    if (selectedElection && selectedElection.year && data?.records) {
      var election = _.cloneDeep(data?.records.filter(item => item.fields.code_election === selectedElection.code_election && item.fields.numero_tour === selectedElection.numero_tour ))
      if (election) {
        election.forEach(element => {
          element.fields.numero_lieu = parseFloat(element.fields.numero_lieu);
        });
      }

      const foundCity = election?.find((c) => c.fields.code_commune === selectedCityData?.data?.code_commune && c.fields.niveau_detail === 'vi')?.fields;
      if (foundCity) {
        setSelectedCityData({ data:foundCity });
      } else {
        setSelectedCityData();
      }

      const foundCenter = election?.find((c) => c.fields?.numero_lieu === selectedCenterData?.data?.numero_lieu && c.fields.niveau_detail === 'li')?.fields;
      if (foundCenter) {
        setSelectedCenterData({ data:foundCenter });
      } else {
        setSelectedCenterData();
      }
    
      const foundBureau = election?.find((c) => c.fields?.numero_lieu === selectedBureauData?.data?.numero_lieu && c.fields.niveau_detail === 'bu')?.fields;
      if (foundBureau) {
        setSelectedCenterData({ data:foundBureau });
      } else {
        setSelectedCenterData();
      }

      var totalBureau = 0;

      data.records.forEach(element => {
        if (element.fields.niveau_detail === "bu") totalBureau++;
        if (element.fields.niveau_detail === "vi" && element.fields.code_commune !== 238) totalBureau++;
        element.fields.numero_lieu = parseFloat(element.fields.numero_lieu);
      });

      //SET PROGRESS
      var closedBureau = 0 ;

      data.records.forEach(element => {
        if (element.fields.niveau_detail === "bu" && element.fields.pourcentage_participation > 0 ) {
          closedBureau++;
        };
        if (element.fields.niveau_detail === "vi" && element.fields.code_commune !== 238 && element.fields.pourcentage_participation > 0 ) {
          closedBureau++;
        };
      });

      if(isDirect) {
        setElectionProgress({
          totalBureau: totalBureau,
          closedBureau: closedBureau,
          progress: 100 * closedBureau / totalBureau
        });
      }

      if (selectedElection.code_election === "P07") {
        election.forEach(element => {
          for (let i = 0 ; i < Object.keys(element.fields).length ; i++) {
            if (element.fields.hasOwnProperty("candidat_" + i)) {
              var name = element.fields["candidat_" + i];
              var lastword = name.lastIndexOf(" ");
              name = name.substring(0, lastword);
              element.fields["candidat_" + i] = name;
            }
          }
        });
      }

      setElectionData(election);
      const rennes = election.find((r) => r.fields?.code_commune === 238 && r?.fields?.niveau_detail === 'vi');
      if (!displayCities && rennes?.fields) {
        setSelectedCityData({ data: rennes.fields });
        setMapKey("2022")
      }

      if (name === 'Régionales' && rennes?.fields) {
        setSelectedCityData({ data: rennes.fields });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, displayCities, isDirect, name, selectedElection]);

  //INIT_ELECTION
  useEffect(() => {
    if(electionData) {
      if (selectedCityData) {
        if (selectedCenterData?.data && selectedCityData?.data?.code_commune === 238) {
          const updatedCenter = getResultsByLevel(electionData , "li").find(lieu => lieu.fields?.numero_lieu === selectedCenterData.data?.numero_lieu )?.fields;
          setBureauList(getResultsByLevel(electionData , "bu")
            .filter((p) => p.fields.nom_lieu === selectedCenterData.data.nom_lieu
            && p.fields.code_commune === selectedCityData.data.code_commune && p.fields.code_election === selectedElection.code_election)
            .sort((a, b) => a.fields.numero_lieu - b.fields.numero_lieu ));
          setCentersList(getResultsByLevel(electionData , "li"));
          setMapData(getResultsByLevel(electionData , "bu"));
          const bureauFound = getResultsByLevel(electionData , "bu").find(lieu => lieu.fields?.numero_lieu === selectedBureauData?.data?.numero_lieu )?.fields;
          if (bureauFound) {
            setResults(bureauFound);
          } else {
            setSelectedBureauData();
            if (updatedCenter) {
              setResults(updatedCenter)
            } else {
              setSelectedCenterData();
            }
          }
        } else if (selectedCityData?.data?.code_commune !== 238 && selectedBureauData?.data) {
          setResults(getResultsByLevel(electionData , "bu").find(lieu => lieu?.fields?.code_commune === selectedCityData.data.code_commune && lieu?.fields?.numero_lieu === selectedBureauData.data.numero_lieu )?.fields)
        } else {
          setSelectedCenterData()
          setSelectedBureauData()
          setCentersList(getResultsByLevel(electionData , "li").filter((li) => li.fields.code_commune === selectedCityData?.data?.code_commune));
          setResults(selectedCityData.data);
          if (selectedCityData?.data?.code_commune === 238 ) {
            if (name === 'Législatives') {
              setDonutIsActive(false);
            }
            setMapData(getResultsByLevel(electionData , "li").filter((li) => li.fields.code_commune === selectedCityData.data.code_commune));
          } else {
            const bureaux = getResultsByLevel(electionData , "bu")?.filter((li) => li.fields.code_commune === selectedCityData?.data?.code_commune)?.sort((a, b) => a.fields.numero_lieu - b.fields.numero_lieu );
            setBureauList(bureaux);
            setMapData(getResultsByLevel(electionData , "vi").filter((li) => li.fields.code_commune === selectedCityData?.data?.code_commune));
          }
        }
      } else {
        setSelectedCenterData()
        setSelectedBureauData()
        setSelectedCityData();
        setCentersList();
        setCitiesList(getResultsByLevel(electionData , "vi"));
        setResults();
        setMapData(getResultsByLevel(electionData , "vi"));
      }
    }
    /* eslint-disable-next-line*/
  }, [electionData , selectedCenterData , selectedBureauData , selectedCityData ]);

  useEffect(() => {
    if (selectedCityData?.data?.code_commune) {
      const query = new URLSearchParams({...selectedElection , code_commune: selectedCityData.data.code_commune }).toString();
      const newUrl = `${location.pathname}?${query}`;
      window.history.pushState({path: newUrl },'', newUrl); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCityData, selectedElection]);

  useEffect(() => {
    if (location.search && citiesList) {
      let search = new URLSearchParams(location.search);
      const cityCode = search.get("code_commune");
      const foundCity = citiesList?.find((c) => c.fields.code_commune === parseInt(cityCode))?.fields;
      if (foundCity) {
        setSelectedCityData({ data:foundCity });
      }
    };
  },[citiesList, location.search]);

  useEffect(() => {
    window.scrollTo(0,0);
    if (selectedCityData) {
      if (selectedCenterData) {
        if (selectedBureauData) {
          setZoomOnBg("scale-bureau");
        } else {
          setZoomOnBg("scale-center");
        }
      } else {
        setZoomOnBg("scale-city")
      }
    } else {
      setMapIsActive(true)
      setZoomOnBg("init-scale");
    }
    /* eslint-disable-next-line*/
  },[selectedCenterData , selectedCityData, selectedBureauData , electionData ]);


  function backToCityResult() {
    setResults(getResultsByLevel(electionData , "vi"));
    setSelectedCenterData();
    setSelectedBureauData();
    setMapIsActive(true)
    setDonutIsActive(false);
  }

  function navBack() {
    if (isInIframe() === false) {
      history.push(backUrl);
    }
  }

  return (
    <>
      <div className={`page ${name !== "Départementales" ? "slide-in-left" : "" } ${mapIsActive ? '' : 'is-list'} full-height ${isInIframe() === true ? "iframe" : ""}`}>
        <div className="container">
          {data && <DataHeader
            name={name}
            tourLabel={tourLabel}
            text={text}
            navBack={navBack}
            isMobile={isMobile}
            isInIframe={isInIframe}
            isDirect={isDirect}
            data={data}
          />
          }
          {isMobile ? <DataMobile
            name={name}
            isDirect={isDirect}
            displayCities={displayCities}
            setSelectedBureauData={setSelectedBureauData}
            setSelectedCenterData={setSelectedCenterData}
            setSelectedCityData={setSelectedCityData}
            selectedCenterData={selectedCenterData}
            selectedBureauData={selectedBureauData}
            selectedCityData={selectedCityData}
            bureauxList={bureauxList}
            centersList={centersList}
            citiesList={citiesList}
            mapIsActive={mapIsActive}
            setMapIsActive={setMapIsActive}
            donutIsActive={donutIsActive}
            setDonutIsActive={setDonutIsActive}
            data={data}
            electionData={electionData}
            mapKey={mapKey}
            results={results}
            setResults={setResults}
            mapData={mapData}
            appSize={appSize}
            isMobile={isMobile}
            electionProgress={electionProgress}
            selectedElection={selectedElection}
            backToCityResult={backToCityResult}
            setSelectedElection={setSelectedElection}
            setTourLabel={setTourLabel}
            sliderMarks={sliderMarks}
          />
          :
          <DataDesktop
            name={name}
            isDirect={isDirect}
            displayCities={displayCities}
            setSelectedBureauData={setSelectedBureauData}
            setSelectedCenterData={setSelectedCenterData}
            setSelectedCityData={setSelectedCityData}
            selectedCenterData={selectedCenterData}
            selectedBureauData={selectedBureauData}
            selectedCityData={selectedCityData}
            bureauxList={bureauxList}
            centersList={centersList}
            citiesList={citiesList}
            mapIsActive={mapIsActive}
            setMapIsActive={setMapIsActive}
            donutIsActive={donutIsActive}
            setDonutIsActive={setDonutIsActive}
            data={data}
            electionData={electionData}
            mapKey={mapKey}
            isMobile={isMobile}
            results={results}
            setResults={setResults}
            mapData={mapData}
            appSize={appSize}
            electionProgress={electionProgress}
            selectedElection={selectedElection}
            backToCityResult={backToCityResult}
          />}
        </div>
        {!isMobile && !isDirect &&
          <TourSlider
            appSize={appSize}
            setSelectedElection={setSelectedElection}
            setTourLabel={setTourLabel}
            marks={sliderMarks}/>
        }
        <Background zoomOnBg={zoomOnBg}/>
      </div>
      {!isMobile && selectedCityData?.data &&
        <div className="hide-mobile">
          <DataViewToogle
            mapIsActive={mapIsActive}
            backToCityResult={backToCityResult}
            setMapIsActive={setMapIsActive}/>
        </div>
      }
    </>
  )
}
