import React, { useState } from 'react'
import Candidats from '../Candidats/Candidats'
import Donut from '../Donut'
import ElectionList from '../ElectionList'
import Map from '../Map'
import Particitpation from '../Participation'
import ProgressBar from '../ProgressBar'
import ResultsList from '../ResultsList'
import SelectBureauMap from '../SelectBureauMap'
import SelectPlaces from '../SelectPlaces'
import TitleNav from '../../Partials/TitleNav/TitleNav'

export default function DataDesktop({
  name,
  isDirect,
  displayCities,
  setSelectedBureauData,
  setSelectedCenterData,
  setSelectedCityData,
  selectedCenterData,
  selectedBureauData,
  selectedCityData,
  bureauxList,
  centersList,
  citiesList,
  mapIsActive,
  donutIsActive,
  setDonutIsActive,
  data,
  electionData,
  mapKey,
  results,
  setResults,
  mapData,
  appSize,
  electionProgress,
  setMapIsActive,
  selectedElection,
  backToCityResult,
}) {
  const [ donutHeight , setDonutHeight ] = useState();
    return (
      <>
        <div className={`col-candidats ${isDirect ? "direct" : ''}`}>
            <TitleNav
              setSelectedCenterData={setSelectedCenterData}
              setSelectedBureauData={setSelectedBureauData}
              setSelectedCityData={setSelectedCityData}
              name={name}
              selectedCenterData={selectedCenterData}
              displayCities={displayCities}
              selectedBureauData={selectedBureauData}
              selectedCityData={selectedCityData}
              />

              {mapIsActive &&
                <Particitpation
                  allDatas={data?.records}
                  selectedCenterData={selectedCenterData}
                  selectedBureauData={selectedBureauData}
                  selectedCityData={selectedCityData}
                  mapIsActive={mapIsActive}
                  mapKey={mapKey}
                  isCanton={mapKey === "cantons2015"}
                  data={mapData}
                  results={results}/>
              }
              {selectedCenterData || selectedBureauData ?
                <>
                  {name === "Départementales" && !selectedCenterData && !selectedBureauData ?
                    <>
                        <ResultsList
                          selectedCityData={selectedCityData}
                          centersList={centersList}
                          citiesList={citiesList}
                          electionData={electionData}
                          name={name}
                        />
                    </>
                    :
                    <>
                      {selectedCityData || !isDirect ?
                        <>
                          <Candidats
                            isMobile={false}
                            results={results}/>
                        </>
                        :
                        <>
                          <ResultsList
                            selectedCityData={selectedCityData}
                            centersList={centersList}
                            citiesList={citiesList}
                            electionData={electionData}
                            name={name}
                          />
                      </>
                      }
                    </>
                  }
                </>
                :
                <>
                {selectedCityData
                  && <Candidats
                    isMobile={false}
                    results={results}
                  />
                }

                {isDirect
                  &&
                  <ResultsList
                    selectedCityData={selectedCityData}
                    centersList={centersList}
                    citiesList={citiesList}
                    electionData={electionData}
                    name={name}
                  />
                }
                </>
              }
              <div className="hide-mobile">
                {electionProgress &&  <ProgressBar electionProgress={electionProgress}/>}
              </div>
        </div>
        <div className="col-dataviz">
        <SelectPlaces
          setSelectedCenterData={setSelectedCenterData}
          setSelectedBureauData={setSelectedBureauData}
          setSelectedCityData={setSelectedCityData}
          options={selectedCityData?.data?.code_commune === 238 ? centersList : citiesList }
          selectedCenterData={selectedCenterData}
          selectedCityData={selectedCityData}
          setMapIsActive={setMapIsActive}
          />
    
          {mapIsActive ?
          <>
            {donutIsActive && donutHeight ?
              <Donut
                donutHeight={donutHeight}
                results={results} />
              :
              null
            }
            <Map
              selectedElection={selectedElection}
              mapKey={mapKey}
              allDatas={data?.records}
              geoData={data?.geojson || null }
              election={name}
              setSelectedCenterData={setSelectedCenterData}
              setSelectedBureauData={setSelectedBureauData}
              setSelectedCityData={setSelectedCityData}
              selectedCenterData={selectedCenterData}
              selectedBureauData={selectedBureauData}
              selectedCityData={selectedCityData}
              setDonutHeight={setDonutHeight}
              appSize={appSize}
              electionData={electionData}
              centersList={centersList}
              setDonutIsActive={setDonutIsActive}
              backToCityResult={backToCityResult}
              setResults={setResults}
              data={mapData}
            />
            {selectedCityData?.data?.code_commune === 238 && selectedBureauData && selectedBureauData.data && bureauxList? 
              <SelectBureauMap
                bureauxList={bureauxList}
                election={name}
                selectedBureauData={selectedBureauData}
                setSelectedBureauData={setSelectedBureauData}
                />
                :
              null
            }
            </>
            :
            <ElectionList
              results={results}
              setSelectedCenterData={setSelectedCenterData}
              selectedCenterData={selectedCenterData}
              selectedCityData={selectedCityData}
              centersList={centersList}
              bureauxList={bureauxList}
              citiesList={citiesList}
            />
          }
        </div>
      </>
    )
  };
