import React, { useEffect, useRef, useState } from 'react';
import arrow from '../../../../assets/images/back-arrow-white.svg';
import { getCandidatsArray } from '../../../../utils';
import SelectElections from '../../SelectElections';
import SelectPlace from '../SelectPlace/SelectPlace';
import styles from './ResultPanel.module.scss';

const Candidat = ({ candidat }) => {

  var pourcentageValue = candidat.pourcentage ? parseFloat(candidat.pourcentage) : 0 ;
  var voixValue = candidat.nb_voix ? parseFloat(candidat.nb_voix) : 0 ;
  return (
    <div className={styles.candidat}>
      <div className={`${styles.row} ${styles.label}`}> 
        <p className={styles.name}>
          {candidat?.name}
        </p>
        <p>
         {pourcentageValue} %
        </p>
        <p>
          {voixValue}
        </p>
      </div>
      <div className={styles.bar}>
        <div className={styles["range-bar"]} style={{ width: `${pourcentageValue}%` }}></div>
      </div>
    </div>
  )
};

export default function ResultPanel({
  activePanel,
  setActivePanel,
  electionsOptions,
  setSelectedElection,
  selectedElection,
  displayCities,
  setSelectedCenterData,
  setSelectedBureauData,
  setSelectedCityData,
  selectedBureauData,
  selectedCenterData,
  selectedCityData,
  setTourLabel,
  results,
  mapIsActive,
  options,
  goBack,
}) {
  const scrollRef = useRef();
  const topRef = useRef();
  const [ candidatsDatas , setCandidatsDatas ] = useState([]);

  useEffect(() => {
    if (activePanel) {
      setCandidatsDatas(getCandidatsArray(results));
      if (scrollRef?.current) {
        window.scrollTo({
          top: 0,
        });
        scrollRef.current.scrollTo({
          top: topRef.current.offsetTop,
          behavior: "smooth"
        });
      }
 
    } else {
      setCandidatsDatas([]);
    }
  }, [activePanel, results]);
  
  let location = results?.nom_lieu || results?.libelle_commune;
  if (results?.niveau_detail === "bu") {
    location = `Bureau ${results.numero_lieu}`;
  }
  return (
    <div ref={scrollRef} className={`${styles.container} ${activePanel ? styles.active : ''}`}>
        <div ref={topRef} className={styles.select}>
          <SelectElections
            electionsOptions={electionsOptions}
            setSelectedElection={setSelectedElection}
            selectedElection={selectedElection}
            setTourLabel={setTourLabel}
          />
        </div>
        <h2>{location}</h2>
        {activePanel &&
          <div className={`${styles.result} ${mapIsActive ? styles['is-map'] : ''}`}>
            {candidatsDatas.length > 0 &&
              <div className={`${styles.row}`}> 
                <p className={styles.name}>
                  Candidat
                </p>
                <p>
                  %
                </p>
                <p>
                  Votes
                </p>
              </div>
            }
            {candidatsDatas?.map((c, i) => <Candidat
              key={c.name + c.nb_voix}
              candidat={c}
            />)}
            <div className={styles.participation}>
              <div className={styles.row}>
                <div className={styles.col}>
                  Taux de participation
                </div>
                <div className={styles.col}>
                  {results?.nb_inscrits} Inscrits
                </div>
              </div>
              <div className={styles.value}>
                <span>{results?.pourcentage_participation} %</span>
              </div>
              <div className={styles.bar}>
                <div className={styles["range-bar"]} style={{ width: `${results?.pourcentage_participation}%` }}></div>
              </div>
              {results?.nb_nuls &&
                <div className={styles.row}>
                  <div className={styles.col}>
                    Exprimés
                  </div>
                  <div className={styles.col}>
                    {results.nb_exprimes}
                  </div>
                </div>
              }
              {results?.nb_nuls &&
                <div className={styles.row}>
                  <div className={styles.col}>
                    Nuls
                  </div>
                  <div className={styles.col}>
                    {results?.nb_nuls}
                  </div>
                </div>
              }
              {results?.nb_blanc &&
                <div className={styles.row}>
                  <div className={styles.col}>
                    Blancs
                  </div>
                  <div className={styles.col}>
                    {results.nb_blanc}
                  </div>
                </div>
              }
            </div>
            {options?.list &&
              <SelectPlace
                results={results}
                displayCities={displayCities}
                setSelectedCenterData={setSelectedCenterData}
                setSelectedBureauData={setSelectedBureauData}
                setSelectedCityData={setSelectedCityData}
                selectedCenterData={selectedCenterData}
                selectedBureauData={selectedBureauData}
                selectedCityData={selectedCityData}
                label={options?.label}
                placeholder={options?.placeholder}
                optionsList={options?.list}
                activePanel={activePanel}
                setActivePanel={setActivePanel}
                isInit={false}
              />
            }
            <div className={styles.back}>
              <button onClick={() => goBack()}>
                <img src={arrow} alt="back-button" />retour
              </button>
            </div>
          </div>
        }
    </div>
  )
}
