import React, { useState , useEffect } from 'react';
import Select from 'react-select';
import { selectStyle } from './selectStyle';
import * as d3 from  'd3';
import * as _ from 'lodash';

// import geoCentre from '../../assets/datas/perimetres-centres-de-vote.geojson';

export default function SelectPlaces({
  mapKey,
  geoData,
  selectedCityData,
  selectedCenterData,
  setSelectedBureauData,
  setSelectedCenterData,
  setSelectedCityData,
  ...props
}) {
  const [ selectedOption , setSelectedOption ] = useState(null);
  const [ options , setOptions ] = useState();

  function handleChange(option) {
    setSelectedOption(option)
    if (selectedCityData?.data?.code_commune !== 238) {
      setSelectedCityData({...option.value });
      setSelectedCenterData();
      setSelectedBureauData();
    }
    if (selectedCityData?.data?.code_commune === 238) {
      setSelectedCenterData(option.value);
      setSelectedBureauData();
    }
    // setSelectedBureauData();
    
    // setSelectedCenterData(option.value);
  };

  useEffect(() => {
    if (selectedCityData?.data?.code_commune !== selectedOption?.value?.data?.code_commune) {
      setSelectedOption(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCityData?.data?.code_commune]);

  function renderPlaceHolder() {
    if(mapKey === "cantons2015" || mapKey === "cantons2021") {
      return "Canton"
    }
    if (selectedCityData?.data?.code_commune === 238) {
      return "centre de vote"
    }
    return "Communes"
  }

  useEffect(() => {
    if (props.options?.length > 0) {
      var newOptions = [];
      if (mapKey === "cantons2015" || mapKey === "cantons2021") {
        props.options.sort((a, b) => a.fields.numero_canton - b.fields.numero_canton).forEach(opt => {
          var place = {}
          place.data = opt.fields
          if(geoData[0]) {
            d3.json(geoData[0]).then(function(geojson) {
              let found  = geojson.features.find(g => g.properties.nom_canton === opt.fields.nom_canton);
              if (found.properties) {
                place.properties = found.properties ;
              }
            })
          }
          newOptions.push({
            value: place,
            label: "Canton " + opt.fields.numero_canton
          });
        })
      } else {
        props.options.forEach(opt => {
          let label = opt.fields.nom_lieu;
          let value = {
            data: opt.fields,
          }
          if (opt.fields.niveau_detail === "vi") {
            label = opt.fields.libelle_commune;
          }
          newOptions.push({
            value,
            label,
          })
        })
      }
      setOptions(_.uniqBy(newOptions ,'label').sort((a,b) => a.label.localeCompare(b.label)));
    }
    // eslint-disable-next-line
  }, [props.options]);
  return (
    <div className="select-container">
      <Select
        placeholder={renderPlaceHolder()}
        styles={selectStyle}
        value={selectedOption}
        onChange={(opt) => handleChange(opt)}
        options={options}
      />
    </div>
  );
}
