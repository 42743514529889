import React, { useEffect, useState } from 'react';
// import Candidats from '../Candidats';
import DataViewToogle from '../DataViewToogle/DataViewToogle';
// import Donut from '../Donut';
// import ElectionList from '../ElectionList';
import Map from '../Map';
import MapResult from '../Mobile/MapResult/MapResult';
import ResultPanel from '../Mobile/ResultPanel/ResultPanel';
import SelectPlace from '../Mobile/SelectPlace/SelectPlace';
import ProgressBar from '../ProgressBar';
// import Particitpation from '../Participation';
// import ProgressBar from '../ProgressBar';
// import ResultsList from '../ResultsList';
// import SelectBureauMap from '../SelectBureauMap';
import SelectElections from '../SelectElections';
// import SelectPlaces from '../SelectPlaces';

import styles from './DataMobile.module.scss';

export default function DataMobile({
  name,
  isDirect,
  displayCities,
  setSelectedBureauData,
  setSelectedCenterData,
  setSelectedCityData,
  selectedCenterData,
  selectedBureauData,
  selectedCityData,
  bureauxList,
  centersList,
  citiesList,
  mapIsActive,
  donutIsActive,
  setDonutIsActive,
  data,
  electionData,
  mapKey,
  results,
  setResults,
  mapData,
  appSize,
  electionProgress,
  setMapIsActive,
  setSelectedElection,
  setTourLabel,
  selectedElection,
  backToCityResult,
  sliderMarks,
  isMobile,
}) {
    const [ activePanel, setActivePanel ] = useState();
    const [options, setOptions] = useState();
  
    function goBack() {
      if (mapIsActive) {
        return setActivePanel();
      }
      const niveau_detail = results.niveau_detail;
      if (niveau_detail === "bu") {
        return setSelectedBureauData();
      }
      if (!selectedBureauData && selectedCenterData) {
        if (!displayCities) {
          setActivePanel();
        }
        return setSelectedCenterData();
      }
      if (displayCities && niveau_detail === "vi") {
        return setActivePanel();
      }
    }
  
    useEffect(() => {
      let selected = null;
      if(!selectedCenterData && selectedCityData?.data?.code_commune === 238) {
        selected = {
          label: "Centres de vote",
          placeholder: "Rechercher un centre de vote...",
          list: centersList,
        };
      }
      if(selectedCenterData && selectedCityData?.data?.code_commune === 238) {
        selected = {
          label: "Bureau de vote",
          placeholder: "Rechercher un bureau de vote...",
          list: bureauxList,
        };
      }
      setOptions(selected)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bureauxList, centersList]);
  
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.date}>
            <SelectElections
              electionsOptions={sliderMarks}
              setSelectedElection={setSelectedElection}
              selectedElection={selectedElection}
              setTourLabel={setTourLabel}
            />
          </div>
          <DataViewToogle
            mapIsActive={mapIsActive}
            setMapIsActive={setMapIsActive}
          />
        </div>
        {mapIsActive ?
          <>
            <Map
              selectedElection={selectedElection}
              mapKey={mapKey}
              allDatas={data?.records}
              geoData={data?.geojson || null }
              election={name}
              setSelectedCenterData={setSelectedCenterData}
              setSelectedBureauData={setSelectedBureauData}
              setSelectedCityData={setSelectedCityData}
              selectedCenterData={selectedCenterData}
              selectedBureauData={selectedBureauData}
              selectedCityData={selectedCityData}
              appSize={appSize}
              electionData={electionData}
              centersList={centersList}
              isMobile={isMobile}
              setDonutIsActive={setDonutIsActive}
              backToCityResult={backToCityResult}
              setResults={setResults}
              data={mapData}
            />
            <MapResult
              name={name}
              results={results}
              electionProgress={electionProgress}
              displayCities={displayCities}
              setSelectedCenterData={setSelectedCenterData}
              setSelectedBureauData={setSelectedBureauData}
              setSelectedCityData={setSelectedCityData}
              selectedCenterData={selectedCenterData}
              selectedBureauData={selectedBureauData}
              selectedCityData={selectedCityData}
              activePanel={activePanel}
              setActivePanel={setActivePanel}
              isMobile={isMobile}
            />
          </>
        :
          <div className={styles.list}>
            <div className={styles.progress}>
              {electionProgress &&  <ProgressBar electionProgress={electionProgress}/>}
            </div>
            <SelectPlace
              results={results}
              displayCities={displayCities}
              setSelectedCenterData={setSelectedCenterData}
              setSelectedBureauData={setSelectedBureauData}
              setSelectedCityData={setSelectedCityData}
              selectedCenterData={selectedCenterData}
              selectedBureauData={selectedBureauData}
              selectedCityData={selectedCityData}
              optionsList={!displayCities && selectedCityData?.data?.code_commune === 238 ? centersList : citiesList }
              activePanel={activePanel}
              setActivePanel={setActivePanel}
              isInit={true}
            />
          </div>
        }
        <ResultPanel
          electionsOptions={sliderMarks}
          setSelectedElection={setSelectedElection}
          selectedElection={selectedElection}
          setTourLabel={setTourLabel}
          results={results}
          goBack={goBack}
          mapIsActive={mapIsActive}
          activePanel={activePanel}
          setActivePanel={setActivePanel}
          setSelectedCenterData={setSelectedCenterData}
          setSelectedBureauData={setSelectedBureauData}
          setSelectedCityData={setSelectedCityData}
          selectedCenterData={selectedCenterData}
          selectedBureauData={selectedBureauData}
          selectedCityData={selectedCityData}
          options={options}
        /> 
      </div>
    )
  };
