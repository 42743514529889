import React from 'react'
import { getResultsByLevel } from '../../utils';
import ElectionRow from './ElectionRow';

export default function ResultsList({
  selectedCityData,
  centersList,
  citiesList,
  electionData,
  name,
}) {
  let list = centersList?.sort((a, b) => b.fields.nb_inscrits - a.fields.nb_inscrits);
    if(name === "Départementales" && electionData) {
      list = getResultsByLevel(electionData , "ca")?.sort((a, b) => a.fields.numero_canton - b.fields.numero_canton );
    }
    if (!selectedCityData && citiesList?.length > 0) {
      let me = getResultsByLevel(electionData , "me");
      if (me?.niveau_detail === "me") {
        list = [{ fields: me }];
      } else {
        list = citiesList?.sort((a,b) => a.fields.libelle_commune?.localeCompare(b.fields.libelle_commune));
      }
    }
  return (
    <div className="center-list">
      {list?.map((center , i ) => {
        let name = center.fields.nom_lieu;
        if (!selectedCityData) {
          name = center.fields.libelle_commune
        }
          return (
            <div key={i} className="list-item-row center">
              <ElectionRow
                results={center.fields}
                isCenterList={true}
                name={name}
                data={center.fields}/>
            </div>
          )
      })}
    </div>
  )
}
