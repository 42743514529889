import React from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';

import Background from '../Partials/Background';

// import home from '../../assets/images/home.png';

import './../../styles/homePage.scss';
import Header from '../Partials/Header';

export default function HomePage({ name , setActivePage, zoomOnBg , setZoomOnBg , links }) {
  setActivePage(name);
  setZoomOnBg("init-scale");
  const isDirect = process.env.REACT_APP_IS_DIRECT === 'true';

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require('../../assets/lottie/home.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="full-height page">
      <div className="slide-in-left">
        <main className="home-page">
          <div className="home-header-container">
            <div>
              <Header/>
              {isDirect &&
                <Link to={'/direct/législatives'}>
                  <button
                    className="btn primary"
                    >
                    Voir les élections<br/>législatives en direct
                  </button>
                </Link>
              }
            </div>
            <div className="illu">
              <Lottie options={defaultOptions}
                isStopped={false}
                isPaused={false}/>
            </div>
          </div>
          <div className='links'>
            {links.map((l ,i) => 
              <Link key={`link-${i}`}to={l.url} className={`link ${l.isHidden && 'is-hidden'}`}>
                <h2>{l.link.title}</h2>
                <p>{!l.isHidden && l.link.years}</p>
              </Link>
            )}
          </div>
        </main>
      </div>
      <Background zoomOnBg={zoomOnBg}/>
    </div>
  )
};
