import React from 'react';
import { useSpring, animated } from 'react-spring';
import { getCandidatsArray } from '../../utils';

import CandidatRow from '../Partials/CandidatRow/CandidatRow';


export default function ElectionRow(props) {
  const { name , data } = props;
  const nb_exprimes = useSpring({ number: data?.nb_exprimes  ? parseFloat(data.nb_exprimes) : 0, from: { number: 0 } });
  const nb_inscrits = useSpring({ number: data?.nb_inscrits ? parseFloat(data.nb_inscrits) : 0 , from: { number: 0 } });
  const nb_blanc = useSpring({ number: data?.nb_blanc ? parseFloat(data.nb_blanc) : 0 , from: { number: 0 } });
  const nb_nuls = useSpring({ number: data?.nb_nuls? parseFloat(data.nb_nuls) : 0 , from: { number: 0 } });
  const pourcentage_participation = useSpring({ number: parseFloat(data.pourcentage_participation) , from: { number: 0 } });

  const candidats = getCandidatsArray(data);

  var total = 0;
  getCandidatsArray(data).forEach( d => {
    return total = total + d.nb_voix
  })

  return (
    <>
      <div className="title">
        {name !== 'Rennes Métropole' && <h2 className="name">{name}</h2>}
        <div className="participation office">
          <div className="pariciptation-range office">
            <h3 className="office"><animated.span>{pourcentage_participation.number.interpolate(x => x.toFixed(2))}</animated.span> % </h3>
            <div className="range-bar office"></div>
          </div>
          <div className="paricipation-mobile">
            <p>
              <animated.span>{nb_inscrits.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> inscrits</span>
            </p>
            <p>
              <animated.span>{nb_exprimes.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> exprimés</span>
            </p>
            {parseFloat(data?.nb_blanc) > 0 &&
              <p>
                <animated.span>{nb_blanc.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> blancs</span>
              </p>
            }
            <p>
              <animated.span>{nb_nuls.number.interpolate(x => x.toFixed(0))}</animated.span><span className="label"> nuls</span>
            </p>
          </div>   
        </div>
      </div>
      <div>
        {candidats.map(( candidat ,i ) => (
          <div key={i} className={props.isCenterList ? "" : "hide-mobile"} > 
            <CandidatRow candidat={candidat} max={candidats[0].pourcentage}/>
          </div>
        ))}
      </div>
    </>
  )
}
