import React from 'react';
import { useHistory , useLocation } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import styles from './TitleNav.module.scss';
// import { Link } from 'react-router-dom';

export default function TitleNav({
  selectedCityData,
  setSelectedCityData,
  selectedCenterData,
  setSelectedCenterData,
  setSelectedBureauData,
  selectedBureauData,
  isMobile = false,
  name,
  displayCities = true
}) {
  const location = useLocation();
  const history = useHistory();
  function backToAll() {
    setSelectedCenterData();
    setSelectedBureauData();
    setSelectedCityData();
    const newUrl = `${location.pathname}`;
    history.push(newUrl); 
  };

  function backToCity() {
    setSelectedCenterData();
    setSelectedBureauData();
  };

  return (
    <div className={`${styles.container} ${isMobile ? styles['is-mobile'] :''}`}>
      {displayCities &&
        <h2
          onClick={() => backToAll()}
          className={selectedCityData?.data?.libelle_commune || selectedCityData?.data?.numero_canton ? styles["not-active"] : "" } >
          Rennes Métropole <BsArrowLeft />
        </h2>
      }

      {(!displayCities && name === 'Législatives') &&
        <h2
          onClick={() => backToCity()}
          className={selectedCityData?.data?.libelle_commune || selectedCityData?.data?.numero_canton ? styles["not-active"] : "" } >
          Rennes <BsArrowLeft />
        </h2>
      }
      {selectedCityData?.data?.libelle_commune ?
        <h2
          onClick={() => backToCity()}
          className={selectedCenterData?.data?.nom_lieu ? styles["not-active"] : "slide-in-left" }>
          {selectedCityData?.data?.libelle_commune} <BsArrowLeft />
        </h2>
        :
        null
      }
      {selectedCenterData?.data?.nom_lieu || selectedCenterData?.data?.numero_canton ?
        <h2
          onClick={() => setSelectedBureauData()}
          className={selectedBureauData && selectedBureauData.data ? styles["not-active"] : "slide-in-left" }>
          {selectedCenterData?.data?.nom_lieu} <BsArrowLeft />
        </h2>
        :
        null
      }
      {selectedBureauData && selectedBureauData.data ?
          <h2 className="slide-in-left">
            Bureau {selectedBureauData.data.numero_lieu}
          </h2>
        : 
        null
      }
    </div>
  );
}
