import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../Partials/Header';
import Background from '../Partials/Background';
import arrow from '../../assets/images/back-arrow.svg';
import '../../styles/LegalNoticePage.scss';

export default function Accessibility({ setActivePage , setZoomOnBg , zoomOnBg, activePage, setDisplayNavMobile }) {
  setActivePage("");
  setZoomOnBg("legal-scale");
  const history = useHistory();

  return (
    <div className="full-height page legal">
      <div className="hide-desktop">
        <Header
          activePage={activePage}
          setDisplayNavMobile={setDisplayNavMobile}/>
      </div>
      <div className="legal-notice-container slide-in-left">
        <section>
          <button
            className="back-arrow"
            onClick={() => history.push("/")}
            >
            <img className="arrow" src={arrow} alt="back-arrow"/> RETOUR À L’ACCUEIL
          </button>
          <h1 className="title">Accessibilité </h1>
          <p>
            Nous avons porté une attention particulière à l'accessibilité des contenus de ce site. Voici les
            développements mis en place et les outils utilisés pour l’accessibilité du site Elections Rennes. 
          </p>
        </section>

        <section>
          <h1>Navigation</h1>
          <h4 className="bold">Le menu</h4>
          <p>
            Un seul menu rassemble les différentes élections accessibles, il est situé en haut. Il est navigable par
            le clavier. 
          </p>
          <h4 className="bold">La structure de page</h4>
          <p>
            Chaque page d’accueil d’élection est construite de la même manière. Cela permet de se retrouver
            rapidement sur la page et d’accéder au contenu. Seuls 2 boutons ressortent pour guider l’utilisateur.
            Il sont accessibles par le clavier. 
          </p>
          <h4 className="bold">Le fil d'ariane</h4>
          <p>
            Le fil d’ariane se trouve en haut à gauche de chaque page de contenu d’élection et permet de
            repérer le niveau de détail en cours et de naviguer facilement dans les niveaux précédents.  
          </p>
          <h4 className="bold">Mobile</h4>
          <p>
            Un travail spécifique a été mené sur la navigation mobile (taille des boutons, position des éléments)
            afin de faciliter l’accès au contenu des élections sur ce périphérique. 
          </p>
        </section>

        <section>
          <h1>Outils d'aide à l'accessibilité</h1>
          <h4 className="bold">Mode de visualisation</h4>
          <p>
            Un mode liste est accessible sur la droite pour permettre l’accès au contenu hors de la carte. Une
            liste déroulante par centre de vote permet d’accéder à ses bureaux. 
          </p>
          <h4 className="bold">Augmentation des contrastes</h4>
          <p>
            Une attention particulière a été portée sur le choix des couleurs, afin que les contrastes soient
            suffisamment forts pour les personnes mal-voyantes. 
          </p>
        </section>

        <section>
          <h1>Outils de tests et validation</h1>
          <p>
            Tests d'accessibilité : <a href="https://developers.google.com/web/tools/chrome-devtools/"rel="noopener noreferrer" target="_blank" >chrome-devtools</a>
          </p>
          <p>
            Analyseur de contrastes : <a href="https://webaim.org/resources/contrastchecker/"rel="noopener noreferrer" target="_blank" >webaim.org</a>
          </p>
        </section>

      </div>
      <Background zoomOnBg={zoomOnBg}/>
    </div>
  )
}
