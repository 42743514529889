import React from 'react';

export default function ProgressBar({ electionProgress }) {
  return (
    <div className="progress-container">
      <p>
        Progression du dépouillement : {electionProgress.progress.toFixed(0)} %
      </p>
      <div className="progress-bar"> 
        <div className={`progress-value ${electionProgress.progress === 100 ? "progress-end" : ""}`} style={{ width: electionProgress.progress + "%" }}></div>
      </div>
    </div>
  );
}
