import * as _ from 'lodash';

export function calculateAverage(array) {
  return  _.sum(array) / array.length ;
}

export function getResultsByLevel(array , level) {
  const result = _.cloneDeep(array).filter(item => {
    return item.fields.niveau_detail === level
  });
  const levels = []
  array.forEach(item => levels.push(item.fields.niveau_detail))
  if (result && result.length > 1) {
    return result
  } else if (result && result.length === 1) {
    return result[0].fields
  }
}

export function getCandidatsArray(obj) {
  let results = [];
  if (!obj) {
    return results;
  }
  let count = 0;
  for (let i = 0 ; i < Object.keys(obj).length ; i++) {
    let candidat = {}
    if (obj.hasOwnProperty("candidat_" + i)) {
      candidat.name = obj["candidat_" + i];
      const nb_voix = obj["nb_voix_" + i] ? parseFloat(obj["nb_voix_" + i]) : 0 ;
      candidat.index = i;
      candidat.nb_voix = nb_voix;
      candidat.pourcentage = obj["pourcentage_" + i];
      count += nb_voix;
      results.push(candidat);
    }
  }
  if (count > 0) {
    return results?.sort((a, b) => a.nb_voix - b.nb_voix).reverse();
  } else {
    return results?.sort((a, b) => a.index - b.index);
  }
}

export function getFakesCandidats(obj) {
  let results = []
  for (let i = 0 ; i < Object.keys(obj).length ; i++) {
    let candidat = {}
    if (obj.hasOwnProperty("candidat_" + i)) {
      candidat.name = obj["candidat_" + i];
      candidat.nb_voix = _.random(0, 8000);
      candidat.pourcentage = _.random(0, 70)
      results.push(candidat);
    }
  }
  return results
}



export function getLegislativeMainResults(array , level) {
  var nb_inscrits = [];
  var nb_bulletins = [];
  var pourcentage_participation = [];
  var nb_nuls = [];
  var nb_exprimes = [];
  getResultsByLevel(array , level ).forEach(item => {
    nb_inscrits.push(item.fields.nb_inscrits);
    pourcentage_participation.push(item.fields.pourcentage_participation);
    nb_bulletins.push(item.fields.nb_bulletins);
    nb_nuls.push(item.fields.nb_nuls);
    nb_exprimes.push(item.fields.nb_exprimes)
  });
  return {
    nb_inscrits : _.sum(nb_inscrits),
    pourcentage_participation: parseFloat((calculateAverage(pourcentage_participation)).toFixed(2)),
    nb_bulletins : _.sum(nb_bulletins),
    nb_nuls :_.sum(nb_nuls),
    nb_exprimes : _.sum(nb_exprimes)
  }
};
