import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../Partials/Header';
import Background from '../Partials/Background';
import arrow from '../../assets/images/back-arrow.svg';

import '../../styles/LegalNoticePage.scss';

export default function LegalNoticePage(props) {
  props.setActivePage("legal");
  props.setZoomOnBg("legal-scale");
  const history = useHistory();

  return (
    <div className="full-height page legal">
      <div className="hide-desktop">
        <Header
          activePage={props.activePage}
        />
      </div>
      <div className="legal-notice-container slide-in-left">

        <section>
          <button
            className="back-arrow"
            onClick={() => history.push("/")}
            >
            <img className="arrow" src={arrow} alt="back-arrow"/> RETOUR À L’ACCUEIL
          </button>
          <h1>Editeur du site</h1>
          <p>
            Le site elections.rennes.fr est édité par la Ville de Rennes.
            Il est géré par la cellule Digicom de la Direction de la communication Ville et Métropole.
          </p>
          <p>
            <span className="bold">Directrice de la publication : </span>Nathalie APPERE, Maire de la Ville de Rennes
          </p>
          <p>
            <span className="bold">Directeur de la Communication de Rennes, Ville et Métropole : </span>Laurent RIERA
          </p>
        </section>

        <section>
          <h1>Développement</h1>
          <p>
            <span className="bold">Bakasable</span><br/>
            <a href="https://www.bakasable.fr/"rel="noopener noreferrer" target="_blank" >www.bakasable.fr </a><br/>
            1 rue Suffren<br/>
            44000 Nantes
          </p>
        </section>

        <section>
          <h1>Hébergement</h1>
          <p>
            Ce site est hébergé par OVH :
            <a href="https://www.ovh.fr"rel="noopener noreferrer" target="_blank" > www.ovh.fr</a>
          </p>
        </section>

        <section>
          <h1>Charte graphique</h1>
          <p>
          <span className="bold">Bakasable</span>, en collaboration avec le service des Prestations graphiques de la Direction de Communication de Rennes, Ville et Métropole.
          </p>
        </section>

        <section>
          <h1>Source des données</h1>
          <p>
            Les données de résultats des élections de la Ville de Rennes sont issues de la plateforme open data de Rennes Métropole.
            Les données de résultats des élections à l'échelle des communes sont issues du Ministère de l'intérieur et de la plateforme open data <a href="https://www.data.gouv.fr/fr/" rel="noopener noreferrer" target="_blank" >data.gouv</a>.<br/>
            Retrouvez l’intégralité de ces données sur <a href="https://data.rennesmetropole.fr/" rel="noopener noreferrer" target="_blank" >data.rennesmetropole.fr</a>.
          </p>
        </section>

        <section>
          <h1>Droits d’auteurs</h1>
          <p>
            Le présents site est la propriété de la Ville de Rennes et Rennes Métropole, qui en sont les auteurs au sens des articles L. 111.1 et suivants du Code de la propriété intellectuelle. Les photographies, textes, slogans, dessins, images, séquences animées sonores ou non ainsi que toutes œuvres intégrées dans le site sont la propriété de la Ville de Rennes, de Rennes Métropole ou de tiers ayant autorisé la Ville de Rennes et/ou Rennes Métropole à les utiliser.
          </p>
          <p>
            La reproduction, sur un support papier ou informatique, du site est autorisée sous réserve qu’elle soit strictement réservée à un usage personnel, excluant tout usage à des fins publicitaires et/ou commerciales et/ou d’informations, et qu’elle soit conforme aux dispositions de l’article L122-5 du Code de la Propriété Intellectuelle. À l’exception des dispositions ci-dessus, toute reproduction, représentation, utilisation ou modification, par quelque procédé que ce soit et sur quelque support que ce soit, de tout ou partie du site, de tout ou partie des contenus qui le composent, sans avoir obtenu l’autorisation préalable de la Ville de Rennes et/ou Rennes Métropole, est strictement interdite et constitue un délit de contrefaçon.
          </p>
        </section>

        <section>
          <h1>Utilisation des cookies</h1>
          <p>
            Lors de la consultation de notre site des cookies sont déposés sur votre ordinateur.
            Les cookies sont des petits fichiers texte (ou témoins de connexion) déposés sur votre ordinateur lors de votre visite sur un site internet. Les cookies sont utilisés par le site internet visité pour envoyer des informations à votre navigateur et permettre à ce navigateur de renvoyer des informations au site internet visité.
          </p>
          <p>
            Les cookies permettent de conserver, pendant une durée maximale de 13 mois, des informations d'état lorsque votre navigateur accède aux différentes pages de notre site internet ou lorsque votre navigateur retourne ultérieurement sur notre site internet.
            Seul l'émetteur d'un cookie peut lire ou modifier les informations qui y sont contenues.
            Les cookies présents sur ce site nous permettent :<br/>
            - d'optimiser votre navigation sur notre site internet,<br/>
            - de réaliser des statistiques de visites.
          </p>
          <p>
            Vous pouvez être alerté quand vous recevez des “cookies”, ou vous opposer à la mémorisation de “cookies” en configurant votre navigateur dans ce sens. Vous pouvez aussi supprimer les “cookies” déjà mémorisés. Si vous considérez que les “cookies” constituent une intrusion dans votre vie privée, configurez votre navigateur pour être alerté de la présence de “cookie” ou pour les refuser, et supprimez ceux déjà mémorisés.
          </p>
          <p>
            Voici comment contrôler et modifier vos souhaits en matière de cookies sur votre navigateur :
          </p>
          <p>
            Si vous utilisez le navigateur <span className="bold">Internet Explorer</span>:<br/>
          </p>
          <p>
            1. Dans le menu Outils, sélectionnez "Options Internet"<br/>
            2. Cliquez sur l'onglet Confidentialité. Vous verrez des paramètres de confidentialité avec six options, qui vous permettent de contrôler le nombre de cookies qui seront enregistrés : Bloquer Tous Les Cookies, Haute, Moyennement Haute, Moyenne (niveau par défaut), Faible, et Accepter Tous Les Cookies
          </p>
          <p>
            Si vous utilisez le navigateur <span className="bold">Mozilla Firefox </span>:<br/>
          </p>
          <p>
            1. Dans le menu Outils, sélectionnez "options"<br/>
            2. Sélectionnez l'onglet Vie Privée dans la fenêtre Options<br/>
            3. Dans le menu déroulant, choisissez "Utiliser les paramètres personnalisés pour l'historique". Cela fera apparaître les options pour les cookies et vous pourrez choisir de les autoriser ou de les refuser en cochant la case appropriée.
          </p>
          <p>
            Si vous utilisez le navigateur <span className="bold">Safari </span>:<br/>
          </p>
          <p>
            1. Dans votre navigateur, choisissez le menu Édition {'>'} Préférences.<br/>
            2. Cliquez sur Sécurité.<br/>
            3. Cliquez sur Afficher les cookies.<br/>
            4. Sélectionnez l'option que vous voulez dans la section "accepter les cookies"
          </p>
          <p>
            Si vous utilisez le navigateur <span className="bold">Google Chrome </span>:<br/>
          </p>
          <p>
            1. Dans le menu Paramètres, sélectionnez "Afficher les paramètres avancés" en bas de page.<br/>
            2. Cliquez sur le bouton "Paramètres de contenu" dans la section Confidentialité.<br/>
            3. La section située en haut de la page qui apparaît alors vous en dit plus à propos des cookies et vous permet d'installer les cookies que vous voulez. Elle vous permet également de supprimer les cookies actuellement stockés."
          </p>
          <p>
            La durée de conservation des cookies est de plusieurs mois, à défaut du renouvellement du consentement de l’Utilisateur ces données seront supprimées.
          </p>
        </section>

        <section>
          <h1>Information concernant vos droits</h1>
          <p>
            Conformément à la réglementation applicable en matière de protection des données personnelles et aux dispositions du Règlement Général sur la Protection des Données (RGPD) UE/2016/679 du 27 avril 2016, vous disposez des droits suivants sur les données qui vous concernent :
          </p>
          <ul>
            <li>
              Droit d'information sur vos données personnelles que nous stockons ;
            </li>
            <li>
              Droit de demander la correction, la suppression ou la limitation du traitement de vos données personnelles ;
            </li>
            <li>
              Droit de vous opposer à un traitement pour des raisons d'intérêt légitime, d'intérêt public ou de profilage, à moins que nous puissions démontrer que des motifs légitimes et impérieux prévalent sur vos intérêts, droits et libertés, ou qu'un tel traitement est effectué aux fins de revendication, de l'exercice ou de la défense de droits en justice ;
            </li>
            <li>
              Droit à la portabilité de vos données ;
            </li>
          </ul>
          <p>
            Vous pouvez à tout moment révoquer votre consentement à la collecte, au traitement et à l'utilisation future de vos données personnelles. Pour plus d’informations, veuillez consulter les chapitres ci-dessus décrivant les traitements de données basés sur votre consentement.
            Pour exercer ces droits ou pour toute question sur le traitement de vos données, vous pouvez contacter La Ville de Rennes et Rennes Métropole par courrier à l’adresse suivante :
          </p>
          <p>
            Ville de Rennes et Rennes Métropole<br/>
            Service Protection de la donnée<br/>
            Hôtel de Rennes Métropole<br/>
            4, avenue Henri Freville<br/>
            CS 93111 35031 Rennes cedex
          </p>
        </section>

        <section>
          <h1>Nous contacter</h1>
          <p>
            Si vous estimez, après nous avoir contactés, que vos droits ne sont pas respectés, vous pouvez adresser une réclamation à la Commission Nationale Informatique et Libertés (CNIL), autorité compétente en matière de protection des données personnelles.
          </p>
        </section>

        <section>
          <h1>Modification de la politique de confidentialité</h1>
          <p>
            Nous pouvons parfois actualiser notre politique de confidentialité. Ces mises à jour seront publiées sur notre site Internet. Toute modification entrera en vigueur dès sa publication. Nous vous recommandons donc de visiter régulièrement notre site Internet pour vous tenir au courant.
          </p>
        </section>

        <section>
          <h1>Droit de reproduction</h1>
          <p>
            La reproduction des pages de ce site est possible à condition de respecter l'intégrité des documents reproduits (pas de modification ni altération d'aucune sorte) et de bien vouloir mentionner la source.
          </p>
          <p>
            Toutes les données et applications ont leur propre licence.
            Les logos de Rennes, Ville et Métropole sont la propriété de ces collectivités. Ainsi, toute reproduction ou modification (totale ou partielle) de ces éléments graphiques sans autorisation préalable et écrite est interdite. Pour tous renseignements, vous pouvez nous contacter via le formulaire de contact ou par courrier postal.
          </p>
        </section>

        <section>
          <h1>Les liens vers le site elections.rennes.fr</h1>
          <p>
            La Ville de Rennes autorise tout site Internet ou tout support à mettre en place un lien hypertexte en direction de son contenu à l'exception de ceux diffusant des contenus à caractère polémique, pornographique, xénophobe, contraire à la décence ou aux bonnes mœurs.
          </p>
          <p>
            Pour autant, l'intégralité de la page liée doit être respectée, et aucune imbrication dans un autre site ne doit être réalisée. De même, le lien doit indiquer clairement la nature des contenus, ainsi que l'adresse exacte de la page.
          </p>
          <p>
            Toute utilisation de liens vers le site elections.rennes.fr à des fins commerciales et publicitaires est interdite.
          </p>
        </section>

      </div>
      <Background zoomOnBg={props.zoomOnBg}/>
    </div>
  )
}
