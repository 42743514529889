import * as _ from 'lodash';

export default function getElection(name) {
  let records;
  let geojson = [];
  let recordsData = [];
  switch (name) {
    case 'Présidentielles':
      records = require('./../assets/datas/presidentielles/presidentielles.json');
      // records = require('./../assets/datas/presidentielles/presidentielles-2022.json');
      records.forEach((r) => {
        geojson.push({
          type: 'Feature',
          geometry: r.fields.geo_shape,
          data: {...r.fields, numero_lieu: parseFloat(r.fields.numero_lieu), code_commune: r.fields.code_commune ? parseInt(r.fields.code_commune) : 238, libelle_commune: r.fields.libelle_commune || 'Rennes' },
          id: r.recordid,
          properties: {
            geo_point: r.fields.geo_point,
          }
        });
      });
      records = records.map((r) => ({
        fields: {
          ...r.fields,
          numero_lieu: parseFloat(r.fields.numero_lieu),
          code_commune: r.fields.code_commune ? parseInt(r.fields.code_commune) : 238 ,
          libelle_commune: r.fields.libelle_commune || 'Rennes'
        }
      }));
      recordsData = _.cloneDeep(records);
      recordsData = recordsData.map((r) => {
        if (r?.fields?.geo_shape) {
          delete r.fields.geo_shape;
        }
        return { fields : r.fields };
      });
      break;

    case "Régionales":
        records = require('./../assets/datas/regionales/regionales.json');
        // records = require('./../assets/datas/presidentielles/presidentielles-2022.json');
        records.forEach((r) => {
          geojson.push({
            type: 'Feature',
            geometry: r.fields.geo_shape,
            data: {...r.fields, code_commune: 238 , numero_lieu: parseFloat(r.fields.numero_lieu), libelle_commune: 'Rennes' },
            id: r.recordid,
            properties: {
              geo_point: r.fields.geo_point,
            }
          });
        });
        records = records.map((r) => ({ fields: {...r.fields, code_commune: 238 , numero_lieu: parseFloat(r.fields.numero_lieu), libelle_commune: 'Rennes' }}))
        recordsData = _.cloneDeep(records);
        recordsData = recordsData.map((r) => {
          if (r?.fields?.geo_shape) {
            delete r.fields.geo_shape;
          }
          return { fields : r.fields };
        });
        break;

    case "Européennes":
        records = require('./../assets/datas/europeennes/europeennes.json');
        // records = require('./../assets/datas/presidentielles/presidentielles-2022.json');
        records.forEach((r) => {
          geojson.push({
            type: 'Feature',
            geometry: r.fields.geo_shape,
            data: {...r.fields, code_commune: 238 , numero_lieu: parseFloat(r.fields.numero_lieu), libelle_commune: 'Rennes' },
            id: r.recordid,
            properties: {
              geo_point: r.fields.geo_point,
            }
          });
        });
        records = records.map((r) => ({ fields: {...r.fields, code_commune: 238 , numero_lieu: parseFloat(r.fields.numero_lieu), libelle_commune: 'Rennes' }}))
        recordsData = _.cloneDeep(records);
        recordsData = recordsData.map((r) => {
          if (r?.fields?.geo_shape) {
            delete r.fields.geo_shape;
          }
          return { fields : r.fields };
        });
        break;

    case "Législatives":
      records = require('./../assets/datas/legislatives/legislatives.json');
      // records = require('./../assets/datas/presidentielles/presidentielles-2022.json');
      // records = records.filter((r) => r.geo_shape ).map((r) => ({ fields: {...r} }))
      records = records.records;
      records.forEach((r) => {
        geojson.push({
          type: 'Feature',
          geometry: r.fields.geo_shape,
          data: {...r.fields, numero_lieu: parseFloat(r.fields.numero_lieu) },
          id: r.recordid,
          properties: {
            geo_point: r.fields.geo_point,
          }
        });
      });
      records = records.map((r) => ({ fields: {...r.fields, numero_lieu: parseFloat(r.fields.numero_lieu) }}))
      recordsData = _.cloneDeep(records);
      recordsData = recordsData.map((r) => {
        if (r?.fields?.geo_shape) {
          delete r.fields.geo_shape;
        }
        return { fields : r.fields };
      });
      const code_elections = []
      
      records.forEach((d) => {
        if (!code_elections.find((c) => c.code_election === d.fields.code_election && c.numero_tour === d.fields.numero_tour)) {
          code_elections.push({code_election: d.fields.code_election , numero_tour: d.fields.numero_tour });
        }
      });
      const city = code_elections?.map((c) => ({fields: { numero_tour: c.numero_tour , code_election: c.code_election , code_commune: 238 , libelle_commune: 'Rennes' , niveau_detail: 'vi' } }));
      recordsData = [...recordsData, ...city];
      break;

    case 'Municipales':
      records = require('./../assets/datas/municipales/municipales.json');
      // records = require('./../assets/datas/presidentielles/presidentielles-2022.json');
      records.forEach((r) => {
        geojson.push({
          type: 'Feature',
          geometry: r.fields.geo_shape,
          data: {...r.fields, code_commune: 238 , libelle_commune: 'Rennes' },
          id: r.recordid,
          properties: {
            geo_point: r.fields.geo_point,
          }
        });
      });
      records = records.map((r) => ({ fields: {...r.fields, code_commune: 238 , libelle_commune: 'Rennes' }}))
      recordsData = _.cloneDeep(records);
      recordsData = recordsData.map((r) => {
        if (r?.fields?.geo_shape) {
          delete r.fields.geo_shape;
        }
        return { fields : r.fields };
      });
      break;
  
    default:
      break;
  }
  return {
    records: recordsData,
    name,
    geojson: {type: 'FeatureCollection', features: geojson },
  }
} 