import React, { useEffect , useState , useRef } from "react";
import { getCandidatsArray } from '../../utils';
import * as _ from "lodash";
import * as d3 from  "d3";

export default function Donut(props){
  const ref = useRef(null);
  const cache = useRef(getCandidatsArray(props.results).sort((a, b) => a.nb_voix - b.nb_voix).reverse());

  const createPie = d3.pie()
    .padAngle(.005)
    .sort(null)
    .value(function (d) {
      return d.nb_voix;
    })

  var radius = props.donutHeight / 2;
  var donutWidth = 20;

  const createArc = d3
    .arc()
    .innerRadius(radius - donutWidth)
    .outerRadius(radius);

  const createTextArc = d3
    .arc()
    .innerRadius(radius * 0.65 )
    .outerRadius(radius * 0.65 );

  const [ candidatsDatas , setCandidatsDatas ] = useState([]);

  useEffect(() => {
    if (props.results) {
      setCandidatsDatas(getCandidatsArray(props.results).sort((a, b) => a.nb_voix - b.nb_voix).reverse());
    }    
  }, [props.results]);


  useEffect(
    () => {
      if (candidatsDatas.length > 0 ) {
        const data = createPie(candidatsDatas);
        const prevData = createPie(cache.current);

        const group = d3.select(ref.current);
        const groupWithData = group.selectAll("g.arc").data(data);

        var colors = d3.scaleLinear()
          .domain([ _.minBy(candidatsDatas, function(o) { return o.nb_voix; }).nb_voix , _.maxBy(candidatsDatas, function(o) { return o.nb_voix; }).nb_voix ])
          .interpolate(d3.interpolateHcl)
          .range([d3.rgb("#FFBBAE"), d3.rgb("#FF3F2D")]);


        groupWithData.exit().remove();

        const groupWithUpdate = groupWithData
          .enter()
          .append("g")
          .attr("class", "arc");

        const path = groupWithUpdate
          .append("path")
          .merge(groupWithData.select("path.arc"));

        const arcTween = (d, i) => {
          const interpolator = d3.interpolate(prevData[i], d);
          return t => createArc(interpolator(t));
        };

        path
          .attr("class", "arc")
          .attr("fill", (d ) => colors(d.data.nb_voix))
          .style("cursor" , "pointer")
          .on("mouseover", function(d , i ) {

            d3.select(this).transition()
              .duration(200)
              .attr('fill', '#000000');
      
            text.transition()		
              .duration(200)	
              .style("opacity" , function( dt , it ) { return it === i ? 1 : 0 })
            })
      
          .on("mouseout", function(d) {
            d3.select(this).transition()
              .duration(200)
              .attr("fill", (d) => colors(d.data.nb_voix))
    
            text.transition()		
              .duration(200)	
              .style("opacity" , 0 )	
          })
          .transition()
          .duration(250)
          .attrTween("d", arcTween);

        const text = groupWithUpdate
          .append("text")
          .merge(groupWithData.select("text"));

        text
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .style("fill", "#000000")
          .style("margin", 5 )
          .style("opacity" , 0 )
          .attr("transform", function(d) {
            return `translate(${createTextArc.centroid(d)})`
          })
          .attr("text-anchor", "middle")
          .attr("fill", "black")
        
        text.text(function (d) {
            return d.data.name ;
          })

        text.append("tspan")
          .attr("x" , "0")
          .attr("dy" , "1.5em")
          .text(function (d) {
            if (d.data && d.data.pourcentage) {
              return parseInt(d.data.pourcentage).toFixed(2) + " %";
            }
          });

        text.append("tspan")
          .attr("x" , "0")
          .attr("dy" , "1.5em")
          .style("font-size", "13px")
          .text(function (d) {
            return d.data.nb_voix + " voix";
          });

        cache.current = getCandidatsArray(props.results).sort((a, b) => a.nb_voix - b.nb_voix).reverse();
      }
    /* eslint-disable-next-line*/
  }, [candidatsDatas]);

  return (

    <div id="donut" className="anim-enter-donut">
      <svg width={props.donutHeight} height={props.donutHeight}>
        <g
          ref={ref}
          transform={`translate(${radius} ${radius})`}
        />
      </svg>
    </div>
  );

};
