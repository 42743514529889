import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import searchIcon from '../../../../assets/images/search.svg';
import arrow from '../../../../assets/images/back-arrow-white.svg';
import styles from './SelectPlace.module.scss';


const Option = ({ option, handleChangeOption }) => {
  return (
    <div
      className={styles.option}
      onClick={() => handleChangeOption({ data: option })}
    >
      <p>{option.label}</p>
      <img src={arrow} alt="arrow" />
    </div>
  )
}

export default function SelectPlace({
  displayCities,
  setSelectedCenterData,
  setSelectedBureauData,
  setSelectedCityData,
  optionsList,
  setActivePanel,
  placeholder = "Rechercher une commune...",
  label = null,
  isInit,
}) {
  const [options, setOptions] = useState(null);
  const [search, setSearch] = useState('');

  function handleChangeOption(opt) {
    setSearch('');
    if (isInit) {
      setSelectedBureauData();
      if (displayCities) {
        setSelectedCityData(opt)
        setSelectedCenterData();
        setActivePanel(opt.data);
      } else {
        setSelectedCenterData(opt);
        setActivePanel(opt.data);
      }
    } else {
      if(opt.data.niveau_detail === "bu") {
        setSelectedBureauData(opt);
      }
      if(opt.data.niveau_detail === "li") {
        setSelectedCenterData(opt);
      }
    }
  }
  useEffect(() => {
    const updatedOptions = optionsList?.map((opt) => {
      let label = opt.fields.nom_lieu;
      if (opt.fields.niveau_detail === "vi") {
        label = opt.fields.libelle_commune;
      }
      if (opt.fields.niveau_detail === "bu") {
        label = `Bureau ${opt.fields.numero_lieu}`;
      }
      return {...opt.fields, label}
    }).sort((a,b) => a.label.localeCompare(b.label));
    setOptions(_.uniqBy(updatedOptions ,'label').sort((a,b) => a.label.localeCompare(b.label)));
  },[optionsList]);
  return (
    <div className={styles.container}>
      {label && 
        <h4>{label}</h4>
      }
      <div className={styles.input}>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={placeholder}
        />
        <img src={searchIcon} alt="search-icon" />
      </div>
      {options?.length > 0 && options
        .filter((opt) => {
          if (search?.length === 0) {
            return opt;
          }
          if (
            search?.length > 0 &&
            opt.label.toLowerCase().includes(search?.toLocaleLowerCase())) {
            return opt;
          }
          return null;
        })
        .map((opt) => <Option
        key={opt.label}
        option={opt}
        handleChangeOption={handleChangeOption}
      />)}
    </div>
  )
}
