import React , { useState , useEffect } from 'react';
import back from '../../assets/images/bureau-back.svg';
import forward from '../../assets/images/bureau-forward.svg';
import '../../styles/selectBureauMap.scss';

export default function SelectBureauMap(props) {
  const { selectedBureauData , bureauxList , setSelectedBureauData , mapKey } = props;
  const [ selectedBureauIndex , setSelectedBureauIndex ] = useState(0);
  const [ bureauIndex , setBureauIndex ] = useState();

  useEffect(() => {
    setSelectedBureauIndex(bureauxList.findIndex(i => i.fields?.numero_lieu === selectedBureauData?.data?.numero_lieu));
    setBureauIndex(bureauxList.findIndex(i => i.fields?.numero_lieu === selectedBureauData?.data?.numero_lieu));
  }, [bureauxList , setSelectedBureauIndex , selectedBureauData , setBureauIndex , selectedBureauIndex , mapKey ])

  useEffect(() => {
    if (selectedBureauIndex !== bureauIndex && bureauxList[bureauIndex] ) {
      let bureau = {}
      bureau.data = bureauxList[bureauIndex].fields;
      setSelectedBureauData(bureau)
    }
  }, [bureauIndex , bureauxList , setSelectedBureauData , selectedBureauIndex ])

  function prev() {
    if (bureauIndex - 1 >= 0) {
      setBureauIndex(bureauIndex - 1);
    }
  };

  function next() {
    if (bureauIndex + 1 < bureauxList.length ) {
      setBureauIndex(bureauIndex + 1);
    }
  };

  function renderRadios() {
    return bureauxList.map(( bu ,i ) => {
      return (
        <button key={i} className="radio" onClick={() => setBureauIndex(i)}>
          <div className={`checked ${i === bureauxList.findIndex(i => i.fields?.numero_lieu === selectedBureauData?.data?.numero_lieu) ? "active" : " "}`}></div>
        </button>
      );
    });
  }

  return (
    <div className="select-bureau">
      <button className={`btn-arrow regular ${bureauIndex === 0 ? "not-active" : ""}`} onClick={() => prev()}>
        Bureau précédent  <img src={back} alt="back"/>
      </button>
      <div className="name-container">
        <div className="radios-container">
          {renderRadios()}
        </div>
        <p>
          {selectedBureauData?.data?.numero_lieu ?
            <span>Bureau {selectedBureauData?.data?.numero_lieu}</span>
            :
            <span>Tous les bureaux</span>
          }
        </p>
      </div>
      <button className={`btn-arrow regular ${bureauIndex + 1 === bureauxList.length ? "not-active" : ""}`}  onClick={() => next()}>
        <img src={forward} alt="back"/> Bureau suivant
      </button>
    </div>
  )
}
