import React from 'react';
import '../../styles/cookiesBar.scss';

export default function CookiesBar(props) {
  const { cookiesBarIsActive , setCookiesBarIsActive } = props;

  function acceptCookies() {
    localStorage.setItem("cookie", "ok");
    setCookiesBarIsActive(false)
  }

  function refuseCookies() {
    setCookiesBarIsActive(false)
  }

  return (
    <div className={`cookies-bar ${cookiesBarIsActive? "" : "not-active"}`}>
      <p>
        Acceptez-vous le dépôt et la lecture de cookies afin d'analyser votre navigation<br/>
        et nous permettre de mesurer l'audience de notre site internet :
      </p>
       <div>
        <button
          style={{ margin: 20 }}
          onClick={() => acceptCookies()}
          className="btn white">oui
        </button>
        <button
          style={{ margin: 20 }}
          onClick={() => refuseCookies()}
          className="btn white">non
        </button>
       </div>
    </div>
  )
}
