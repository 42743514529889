import React, { useState , useEffect , useCallback } from 'react';
import { makeStyles , withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import * as _ from 'lodash';

export default function TouSlider(props) {
  const [ value , setValue ] = useState();
  const [ min , setMin ] = useState(0);
  const [ max , setMax ] = useState(0);
  
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      // maxWidth: 1300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      minHeight: 80,
      position: 'absolute',
      bottom: 0,
      marginLeft: 0,
      marginRight: 0
    },
    margin: {
      width: props.appSize.width > 1025 ? '90%' : '80%' ,
    },
  }));

  const classes = useStyles();

  const CustomSlider = withStyles({
    root: {
      color: '#FF7D67',
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      display: 'none'
    },
    markLabel: {
      color: '#000000',
      fontSize: 13,
      opacity: 0.5,
      marginTop: -55,
    },
    markLabelActive: {
      opacity: 1,
      color: '#000000',
    },
    mark: {
      backgroundColor: '#9e9e9e',
      height: 15,
      width: 1,
      marginTop: -20,
      opacity: 1,
    },
    markActive: {
      opacity: 1,
      color: '#000000',
    },
    track: {
      height: 8,
      borderRadius: 0,
    },
    rail: {
      height: 8,
      borderRadius: 0,
      opacity: 1,
      background: 'linear-gradient(to right, #9e9e9e '+ ((((value-1) * 100 )/ max) - 1 ) +'%, #FF7D67 '+ (((value * 100 )/ max) - 1 ) +'%, #9e9e9e '+ (((value * 100 )/ max ) - 1 ) + '%, #9e9e9e 100% )'
    },
  })(Slider);

  const handleChange = useCallback((e, value )=> {
    setValue(value)
  }, []);

  const handleChangeSelectedElection = useCallback((value )=> {
    props.setSelectedElection(value);
  }, [props]);

  const handleChangeTourLabel = useCallback((value )=> {
    props.setTourLabel(value);
  }, [props]);

  useEffect(() => {
    if (props.marks) {
      setValue(_.maxBy(props.marks, function(o) { return o.value; }).value);
      setMax(_.maxBy(props.marks, function(o) { return o.value; }).value);
      setMin(_.minBy(props.marks, function(o) { return o.value; }).value);
    }
  }, [props.marks]);

  useEffect(() => {
    if (value !== undefined && props.marks && props.marks.length > 0) {
      if(props.marks.find(item =>item.value === value)) {
        handleChangeTourLabel(props.marks.find(item =>item.value === value).label)
      }
      handleChangeSelectedElection(props.marks.find(item =>item.value === value).dataSet);
    }
  }, [value , props.marks , props.setTourLabel , handleChangeSelectedElection , handleChangeTourLabel ])

  function renderSlider() {
    if (props.marks && props.appSize && props.appSize.width > 768 ) {
      return <CustomSlider
        min={min}
        max={max}
        defaultValue={max}
        value={value}
        onChange={handleChange}
        track={false}
        aria-labelledby="track-false-slider"
        step={( max +1 ) / props.marks.length }
        valueLabelDisplay="on"
        marks={props.marks}
        />
    }
  }

  return (
    <>
      { props.appSize && props.appSize.width > 768 ?
        <div data-testid="slider" className={classes.root}>
          <div className={classes.margin}>
            {renderSlider()}
          </div>
        </div>
        :
        null
      }
    </>
  );
}