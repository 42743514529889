import React from 'react'
import bgRennes from '../../assets/images/rennes-grey.png';
import '../../styles/background.scss'

export default function Background({ zoomOnBg }) {
  return (
    <div id="background" className="bg-container">
      <div className="img-container">
        <img src={bgRennes} className={zoomOnBg} alt="rennes-background"/>
      </div>
    </div>
  )
}
