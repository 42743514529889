import React from 'react';
import { Link } from 'react-router-dom';

import './../../styles/footer.scss';

import logo from '../../assets/images/logo-rennes.svg';
// import facebook from '../../assets/images/facebook.svg';
// import instagram from '../../assets/images/instagram.svg';
// import linkedin from '../../assets/images/linkedin.svg';

export default function Footer() {
  return (
    <footer>
      <div>
        <div className="logo">
          <a href="https://metropole.rennes.fr/" rel="noopener noreferrer" target="_blank" >
            <img src={logo} alt="logo-rennes" />
          </a>
        </div>
        <div className="links">
          <Link to={"/accessibility"} >
            <p>Accessibilité</p>
          </Link>
          <Link
              to={"/legal-notice"}
              >
            <p>Mentions légales</p>
          </Link>
          <a href="https://data.rennesmetropole.fr/" rel="noopener noreferrer" target="_blank">
            <p>Open data</p>
          </a>
          <a href="https://metropole.rennes.fr/sinscrire-sur-les-listes-electorales?idParent=109&typeParent=taxonomy_term" rel="noopener noreferrer" target="_blank">
            <p>Êtes-vous inscrit sur les listes électorales ?</p>
          </a>
        </div>
        {/* <div className="social-icons">
          <a href="https://some-website.com/my-social-media-url" rel="noopener noreferrer" target="_blank" >
            <img src={facebook} alt="facebook" className="icon" />
          </a>
          <a href="https://some-website.com/my-social-media-url" rel="noopener noreferrer" target="_blank" >
            <img src={instagram} alt="instagram" className="icon" />
          </a>
          <a href="https://some-website.com/my-social-media-url" rel="noopener noreferrer" target="_blank" >
            <img src={linkedin} alt="linkedin" className="icon" />
          </a>
        </div> */}
        <div className="address">
          <p>
            <span>Mairie de Rennes</span><br/>
            Hôtel de Ville -
            place de la Mairie<br/>
            CS 63126 - 35031 Rennes Cedex<br/>
            Tel: 02 23 62 10 10
          </p>
        </div>
      </div>
    </footer>
  )
}
