import React, { useState , useEffect } from 'react';
import Select from 'react-select';
import { selectStyle } from './selectStyle';
// import * as d3 from  'd3';

// import geoCentre from '../../assets/datas/perimetres-centres-de-vote.geojson';

export default function SelectElections({
  setSelectedElection,
  selectedElection,
  electionsOptions,
  setTourLabel,
}) {
  const [ selectedOption , setSelectedOption ] = useState();
  const [ options ,setOptions ] = useState();

  useEffect(() => {
    if (electionsOptions) {
      setOptions(electionsOptions);
      setSelectedElection(electionsOptions[electionsOptions.length - 1].dataSet);
      setTourLabel(electionsOptions[electionsOptions.length - 1].label);
    }
     /* eslint-disable-next-line*/
  }, [electionsOptions])

  useEffect(() => {
    if (selectedOption!== undefined && selectedOption && selectedOption.dataSet ) {
      setSelectedElection(selectedOption.dataSet);
      setTourLabel(selectedOption.label)
    }
     /* eslint-disable-next-line*/
  }, [selectedOption])

  return (
    <div className="select-container">
      <Select
        placeholder="Elections"
        styles={selectStyle}
        isSearchable={false}
        value={options ? options.filter( item => item.dataSet === selectedElection) : null}
        onChange={(v) => setSelectedOption(v)}
        options={options}
      />
    </div>
  );
}
