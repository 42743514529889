import React , { useEffect } from 'react';
import { getCandidatsArray } from '../../utils';
import * as _ from 'lodash';
import useDimensions from 'react-use-dimensions';
import * as d3 from  'd3';

export default function Map({
  data,
  mapKey ,
  geoData,
  setDonutIsActive ,
  election ,
  setDonutHeight ,
  setResults ,
  selectedCityData,
  selectedCenterData ,
  selectedBureauData ,
  setSelectedBureauData ,
  setSelectedCenterData ,
  setSelectedCityData,
  selectedElection,
  centersList,
  allDatas,
  isMobile,
}) {

  const [ mapRef, mapSize] = useDimensions();
  const code_commune = selectedCityData?.data?.code_commune; 

  useEffect(() => {
    return () => {
      d3.selectAll("svg").remove();
    }
  }, []);

  useEffect(() => {
    if (mapSize.width && mapSize.height && geoData && data && data.length > 0) {
      renderMap();
      if (setDonutHeight) {
        setDonutHeight(mapSize.height *.85 );
      }
    }
    /* eslint-disable-next-line*/
  }, [geoData, data , mapSize.width , mapSize.height , selectedCenterData  , selectedBureauData , selectedCityData , code_commune ]);
  function renderMap() {
    const dataSet = _.cloneDeep(data);
    d3.select("#map > svg").remove();
    d3.select(".tooltip").remove();

    var scale = 205000;

    const width = mapSize.width,
          height = mapSize.height;

    const path = d3.geoPath();

    var tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")				
      .style("opacity", 0);
  
    const svg = d3.select('#map').append("svg")
        .attr("width", width )
        .attr("height", height)
        .call(d3.zoom()
          .scaleExtent([1, 2.5])
          .translateExtent([
            [0, 0],
            [width, height],
          ])
          .on("zoom", function () {
            if (isMobile) {
              deps.attr("transform", d3.event.transform);
            }
        }))
        .attr("position", "relative");

    var minParticipation = _.minBy(allDatas, function(o) { return o.fields.pourcentage_participation; }).fields.pourcentage_participation;
    var maxParticipation = _.maxBy(allDatas, function(o) { return o.fields.pourcentage_participation; }).fields.pourcentage_participation;
    
    var opacityByParticipation = d3.scaleLinear()
        .domain([ minParticipation , maxParticipation ])
        .range([ 0.1 , 1 ]);
    
    const deps = svg.append("g");

    if (mapKey === "cantons2015" || mapKey === "cantons2021" ) {
      if (selectedCenterData && selectedCenterData.data.numero_canton ) {
        if (selectedBureauData) {
          renderBureau();
        } else {
          renderCanton()
        }
      } else {
        renderAllCantons();
      }
    } else {
      if (selectedCityData) {
        if (selectedCenterData) {
          if (selectedBureauData) {
            renderBureau();
          } else {
            renderCenter();
          }
        } else {
          renderCity();
        }
      } else {
        renderAllCities();
      }
    }

    function renderAllCantons() {
      setDonutIsActive(false);
      setSelectedBureauData();
      var projection = d3.geoMercator()
      .center([-1.6777926, 48.117266])
      .scale(scale)
      .translate([width / 2, height / 2]);
      
      path.projection(projection);
      d3.json(geoData[mapKey][0]).then(function(geojson) {
          for (var j = 0; j < geojson.features.length; j++)  {  
            var numCenterMap = _.cloneDeep(geojson.features[j].properties.nom_canton);
            for (var i = 0; i < centersList.length; i++) {
              var numCenter = centersList[i].fields.nom_canton.trim();
              var centerValue = _.cloneDeep(centersList[i].fields);
              if (numCenterMap.trim() === numCenter) {
                geojson.features[j].data = _.cloneDeep(centerValue);
                break;
              }

            }
  
        }

        deps.selectAll("path")
          .data(geojson.features)
          .enter()
          .append("path")
          .attr("d", path)
          .style("stroke", "white")
          .style("fill" , "transparent")
          .style("cursor", "pointer")
          .style('opacity' , 0 )
          .style('display' , (d) => !d.data ? 'none': 'block')
          .on("click", (d) => clicked(d))
          .on("mouseover", function(d) {
            if (d.data && centersList) {
              d3.select(this).transition()		
              .duration(200)
              .style("opacity", .9)		
              .style("fill", "#000000");

              let dataLieu = centersList.find(lieu => lieu.fields.numero_canton === d.data.numero_canton ).fields
              let results = getCandidatsArray(dataLieu).sort((a, b) => a.nb_voix - b.nb_voix).reverse().filter( candidat => candidat.pourcentage > 5 );
              
              tooltip.transition()		
                .duration(200)		
                .style("opacity", .9);

              tooltip.html( "<p class='bold name'>Canton "+  d.data.numero_canton +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                .style("left", (d3.event.pageX) + "px")		
                .style("top", (d3.event.pageY - 28) + "px");	
            }})			
          .on("mouseout", function(d) {
            d3.select(this)
            .transition()		
            .duration(200)
            .style("opacity" , function(d) { 
              if (d.data) {
                return opacityByParticipation(d.data.pourcentage_participation);
              } else {
                return 0.1;
              }
            })		
            .style("fill" , function(d) { 
              if (d.data) {
                return "#1D5993";
              } else {
                return 'red';
              }
            });

            tooltip.transition()		
              .duration(500)		
              .style("opacity", 0);	
          })
          .transition()
          .duration(250)
          .style("fill" , function(d) { 
            if (d.data) {
              return "#1D5993";
            } else {
              return 'red';
            }
          })
          .style("opacity" , function(d) { 
            if (d.data) {
              return opacityByParticipation(d.data.pourcentage_participation);
            } else {
              return 0.1;
            }
          });
        
        svg.selectAll("g")
          .attr("opacity" , 0)
          .transition()
          .duration(750)
          .attr("opacity" , 1);
      });

      function clicked(d) {
        if (d.data) {
          setSelectedCenterData(d);
          setResults(_.cloneDeep(d.data))
          setDonutIsActive(true);
        }
      }
    }

    function renderCanton() {
      setDonutIsActive(true);
      var offset = [width/2, height/2];
      var center = selectedCenterData.properties? [selectedCenterData.properties.geo_point_2d[1], selectedCenterData.properties.geo_point_2d[0]] : [-1.6777926, 48.117266]
      var projection = d3.geoMercator().scale(scale).center(center)
      .translate(offset);

      var path = d3.geoPath().projection(projection);

      d3.json(geoData[mapKey][1]).then(function(geojson) {
        let selectedCanton = selectedCenterData.properties?.code_canton;
        selectedCanton = parseFloat(selectedCanton?.substring(selectedCanton.length - 2));
        geojson.features = geojson.features.filter(item => item.properties.nucanton === selectedCanton || item.properties.nom_canton === selectedCenterData.properties.nom_canton );
        var allBounds = []; 
      	geojson.features.forEach(function(d){
          allBounds.push(path.bounds(d));
        });
      
        var bound0 = d3.min(allBounds, function(d){return d[0][0]});
        var bound1 = d3.min(allBounds, function(d){return d[0][1]});
        var bound2 = d3.max(allBounds, function(d){return d[1][0]});
        var bound3 = d3.max(allBounds, function(d){return d[1][1]});

        var dx = bound2 - bound0,
          dy = bound3 - bound1,
          x = (bound0 + bound2) / 2,
          y = (bound1 + bound3) / 2,
          scale = .45 / Math.max(dx / width, dy / height),
          translate = [width / 2 - scale * x, height / 2 - scale * y];
          
          for (var j = 0; j < geojson.features.length; j++)  {

            var numBureauMap = geojson.features[j].properties.num_bureau ;
            var numBureau2021 = geojson.features[j].properties.numero_lieu ;
            for (var i = 0; i < dataSet.length; i++) {

              var numBureau = dataSet[i].fields.numero_lieu ;
    
              var dataSetValue = dataSet[i].fields;
          
              if (numBureauMap === numBureau || numBureau2021 === numBureau) {
                geojson.features[j].data = dataSetValue;
                break;
              }

            }
    
        }

        deps.selectAll("path")
          .data(geojson.features)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("stroke-width" , 0 + "px")
          .style("stroke", function(d) {
            if (d.data) {
              return "white" ;
            } else if (!d.data) {
              return "white";
            } else {
              return 'transparent';
            }
          })
          .style("cursor", "pointer")
          .on("click", (d) => clicked(d))
          .on("mouseover", function(d) {
            if (d.data) {
              let results = getCandidatsArray(d.data).sort((a, b) => a.nb_voix - b.nb_voix).reverse().filter( candidat => candidat.pourcentage > 5 );
              tooltip.transition()		
                .duration(200)		
                .style("opacity", .9);		
              tooltip.html( "<p class='bold name'>Bureau "+ d.data.numero_lieu +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                .style("left", (d3.event.pageX) + "px")		
                .style("top", (d3.event.pageY - 28) + "px");	
            }})			
          .on("mouseout", function(d) {		
            tooltip.transition()		
              .duration(500)		
              .style("opacity", 0);	
          })
          .style('opacity' , 0 )
          .style("fill" , "transparent")
          .transition()
          .duration(250)
          .style("fill" , function(d) {
            if (d.data) {
              return "#1D5993" ;
            } else if (!d.data) {
              return 'red';
            } else {
              return 'transparent';
            }
          }).style("opacity" , function(d) { 
            if (d.data) {
              return opacityByParticipation(d.data.pourcentage_participation);
            } else {
              return 0.1;
            }
          });

          svg.selectAll("g").transition()
            .duration(750)
            .attr("transform", "translate(" + translate + ")scale(" + scale + ")");
      });

      function clicked(d) {
        if (d.data) {
          setSelectedBureauData(d)
        }
      }

    }

    function renderAllCities() {
      setDonutIsActive(false);
      const map = {...geoData};
      var projection = d3.geoMercator()
      .center([-1.6777926, 48.118])
      .scale(isMobile ? scale / 5 : scale / 3.8)
      .translate(isMobile ? [(width / 2 + 30), (height / 2)] :[width / 2, (height / 2) + 30]);

      path.projection(projection);

      const features = map.features.filter((p) => {
        if(p.data.niveau_detail === 'vi'
          && p.data.code_election === selectedElection.code_election
          && p.data.numero_tour === selectedElection.numero_tour
        ) return p;
        return null;
      });

      deps.selectAll("path")
        .data(features , function(d) { return d.id })
        .enter()
        .append("path")
        .attr("d", (d) => path(d))
        .attr("id", (d) => d.id)
        .style("stroke", "white")
        .style("fill" , "transparent")
        .style("cursor", "pointer")
        .style('opacity' , 0 )
        .on("click", (d) => clicked(d))
        .on("mouseover", function(d) {
          if (d.data) {
            d3.select(this).transition()		
            .duration(200)
            .style("opacity", .9)		
            .style("fill", "#000000");
            let results = getCandidatsArray(d.data).sort((a, b) => a.nb_voix - b.nb_voix).reverse().filter( candidat => candidat.pourcentage > 5 );
            tooltip.transition()		  
              .duration(200)		
              .style("opacity", .9);


            tooltip.html( "<p class='bold name'>" + d.data.libelle_commune +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
              .style("left", (d3.event.pageX) + "px")		
              .style("top", (d3.event.pageY - 28) + "px");	
            
          }

        })			
        .on("mouseout", function(d) {
          d3.select(this)
          .transition()		
          .duration(200)
          .style("opacity" , function(d) { 
            if (d.data) {
              if (!d.data.nb_inscrits && d.data.nb_inscrits !== 0) {
                return 0.75;
              }
              return opacityByParticipation(d.data.pourcentage_participation);
            } else {
              return 0.1;
            }
          })		
          .style("fill" , function(d) { 
            if (d.data) {
              return "#1D5993";
            } else {
              return 'red';
            }
          });

          tooltip.transition()		
            .duration(500)		
            .style("opacity", 0);	
        })
        .transition()
        .duration(250)
        .style("fill" , function(d) { 
          if (d.data) {
            return "#1D5993";
          } else {
            return 'red';
          }
        })
        .style("opacity" , function(d) { 
          if (d.data) {
            if (!d.data.nb_inscrits && d.data.nb_inscrits !== 0) {
              return 0.75;
            }
            return opacityByParticipation(d.data.pourcentage_participation);
          } else {
            return 0.1;
          }
        });

        svg.selectAll("g")
          .attr("opacity", 0)  
          .transition()
          .duration(750)
          .attr("opacity", 1);

      function clicked(d) {
        if (d.data) {
          setSelectedCityData(d);
          setResults(_.cloneDeep(d.data))
          setDonutIsActive(true);
        }
      }
    }

    function renderCity() {
      let translate = [width / 2, height / 2];
      var projection;
      const map = {...geoData};

        if (election === 'Régionales') {
          map.features = map.features.filter((p) => {
            if(p.properties?.numero_lieu === 35740) return null;
            if(p.properties?.numero_lieu === 35136) return null;
            if(p.properties?.numero_lieu === 35135) return null;
            return p
          })
        }

        map.features = map.features.filter((p) => {
          if (selectedCityData?.data?.code_commune === 238 ) {
            if(p.data.niveau_detail === 'li' 
              && p.data.code_commune === selectedCityData.data.code_commune 
              && p.data.code_election === selectedElection.code_election
              && p.data.numero_tour === selectedElection.numero_tour
            ) return p;
          }
          if (selectedCityData?.data?.code_commune !== 238 ) {
            if(p.data.niveau_detail === 'vi'
              && p.data.code_commune === selectedCityData.data.code_commune
              && p.data.code_election === selectedElection.code_election
              && p.data.numero_tour === selectedElection.numero_tour
            ) return p;
          }
          return null;
        });
        
        setDonutIsActive(true);

        projection = d3.geoMercator().fitSize(isMobile ? [width / 1.3 , height * 0.7] : [width / 2, height / 2], map )
        translate = isMobile ? [width / 8, height / 6.5]: [width / 4, height / 4];
        path.projection(projection);

        deps.selectAll("path")
          .data(map.features , function(d) { return d.id })
          .enter()
          .append("path")
          .attr("d", (d) => path(d))
          .attr("id", (d) => d.id)
          .style("stroke", "white")
          .style("fill" , "transparent")
          .style("cursor", selectedCityData?.data?.code_commune === 238 ? 'pointer' : 'default')
          .style('opacity' , 0.2 )
          .on("click", (d) => clicked(d))
          .on("mouseover", function(d) {
            if (selectedCityData?.data?.code_commune === 238 ) {
              d3.select(this).transition()		
              .duration(200)
              .style("opacity", .9)		
              .style("fill", "#000000");
              let results = getCandidatsArray(d.data).sort((a, b) => a.nb_voix - b.nb_voix).reverse().filter( candidat => candidat.pourcentage > 5 );
              tooltip.transition()		
                .duration(200)		
                .style("opacity", .9);
              let name = d.data.nom_lieu;

              if (election === "Législatives") {
                tooltip.html( "<p class='bold name'><span class='text-gray'>"+ d.data.nom_circonscription + "</span><br/>" + d.data.nom_lieu +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                  .style("left", (d3.event.pageX) + "px")		
                  .style("top", (d3.event.pageY - 28) + "px");	
              } else if (selectedCityData?.data?.code_commune === 238) {
                tooltip.html( "<p class='bold name'>" + name +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                  .style("left", (d3.event.pageX) + "px")		
                  .style("top", (d3.event.pageY - 28) + "px");
              }
            }
          })			
          .on("mouseout", function(d) {
            d3.select(this)
            .transition()		
            .duration(200)
            .style("opacity" , function(d) { 
              if (d.data) {
                return opacityByParticipation(d.data.pourcentage_participation);
              } else {
                return 0.1;
              }
            })		
            .style("fill" , function(d) { 
              if (d.data) {
                return "#1D5993";
              } else {
                return 'red';
              }
            });
            tooltip.transition()		
              .duration(500)		
              .style("opacity", 0);	
          })
          .transition()
          .duration(250)
          .style("fill" , function(d) { 
            if (d.data) {
              return "#1D5993";
            } else {
              return 'red';
            }
          })
          .style("opacity" , function(d) { 

            if (d.data) {
              return opacityByParticipation(d.data.pourcentage_participation);
            } else {
              return 0.2;
            }
          });
        
        if (selectedCityData?.data?.code_commune !== 238 ) {
          svg.selectAll("g")
            .attr("transform", "translate(" + [width / 2, height / 2] + ") scale(0)")  
            .transition()
            .duration(750)
            .attr("transform", "translate(" + translate + ") scale(1)");
        } else {
          svg.selectAll("g")
            .attr("transform", "translate(" + [width / 2, height / 2] + ") scale(0)")  
            .attr("opacity" , 0)
            .transition()
            .duration(750)
            .attr("transform", "translate(" + translate + ") scale(1)")
            .attr("opacity" , 1);
        }

      function clicked(d) {
        if (d.data && selectedCityData?.data?.code_commune === 238) {
          if (selectedCityData?.data?.code_commune === 238 ) {
            setSelectedCenterData(d);
            setResults(_.cloneDeep(d.data))
            setDonutIsActive(true);
          } else {
            setSelectedBureauData(d);
          } 
        }
      }
    }

    function renderCenter() {
      setDonutIsActive(true);

      const map = {...geoData};
    
      map.features = map.features.filter((p) => {
        if(p.data.niveau_detail === 'bu'
          && p.data.num_centre === selectedCenterData.data.numero_lieu
          && p.data.code_commune === selectedCityData.data.code_commune
          && p.data.code_election === selectedElection.code_election
          && p.data.numero_tour === selectedElection.numero_tour
        ) return p;
        if(p.data.niveau_detail === 'bu'
          && p.data.nom_lieu === selectedCenterData.data.nom_lieu
          && p.data.code_commune === selectedCityData.data.code_commune
          && p.data.code_election === selectedElection.code_election
          && p.data.numero_tour === selectedElection.numero_tour
        ) return p;
        return null;
      });

      // var center = selectedCenterData?.data.geo_point ? [selectedCenterData.data.geo_point[1], selectedCenterData.data.geo_point[0]] : [-1.6777926, 48.117266]
      var projection = d3.geoMercator().fitSize([width / 2, height / 2], map )
      var translate = [width / 4, height / 4];
      path.projection(projection);

      deps.selectAll("path")
        .data(map.features , function(d) { return d.id })
        .enter()
        .append("path")
        .attr("d", (d) => path(d))
        .attr("id", (d) => d.id)
        .attr("stroke-width" , 0.5 + "px")
        .style("cursor", "pointer")
        .style("stroke", function(d) {
          if (d.data) {
            return "white" ;
          } else if (!d.data && d.properties.num_centre === selectedCenterData.data.numero_lieu) {
            return "white";
          } else {
            return 'transparent';
          }
        })
        .on("click", (d) => clicked(d))
        .on("mouseover", function(d) {
          if (d.data) {
            d3.select(this).transition()		
              .duration(200)
              .style("opacity", .9)
              .style("fill", "#000000");

            let results = getCandidatsArray(d.data).sort((a, b) => a.nb_voix - b.nb_voix).reverse().filter( candidat => candidat.pourcentage > 5 );
            tooltip.transition()		
              .duration(200)		
              .style("opacity", .9);

            if (election === "Législatives") {
              tooltip.html( "<p class='bold name'><span class='text-gray'>"+ d.data.nom_circonscription + "</span><br/>Bureau " + d.data.numero_lieu +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                .style("left", (d3.event.pageX) + "px")		
                .style("top", (d3.event.pageY - 28) + "px");	
            } else {
              tooltip.html( "<p class='bold name'>Bureau "+ d.data.numero_lieu +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                .style("left", (d3.event.pageX) + "px")		
                .style("top", (d3.event.pageY - 28) + "px");	
            }

          }})			
        .on("mouseout", function(d) {

          d3.select(this).transition()		
            .duration(200)
            .style("opacity" , function(d) { 
              if (d.data) {
                return opacityByParticipation(d.data.pourcentage_participation);
              } else {
                return 0.1;
              }
            })		
            .style("fill" , function(d) { 
              if (d.data) {
                return "#1D5993";
              } else {
                return 'red';
              }
            });

          tooltip.transition()		
            .duration(500)		
            .style("opacity", 0);	
        })
        .style('opacity' , 0 )
        .style("fill" , "transparent")
        .transition()
        .duration(250)
        .style("fill" , function(d) {
          if (d.data) {
            return "#1D5993" ;
          } else if (!d.data) {
            return 'red';
          } else {
            return 'transparent';
          }
        }).style("opacity" , function(d) { 
          if (d.data) {
            return opacityByParticipation(d.data.pourcentage_participation);
          } else {
            return 0.1;
          }
        });

        svg.selectAll("g")
          .attr("transform", "translate(" + [width / 2, height / 2] + ") scale(0)")  
          .transition()
          .duration(750)
          .attr("transform", "translate(" + translate + ") scale(1)");

      function clicked(d) {
        if (d.data) {
          setSelectedBureauData(d)
        }
      }
    }

    function renderBureau() {
      const map = {...geoData};
    
      map.features = map.features.filter((p) => {
        if(p.data.niveau_detail === 'bu'
          && p.data.numero_lieu === selectedBureauData.data.numero_lieu
          && p.data.num_centre === selectedCenterData.data.numero_lieu
          && p.data.code_commune === selectedCityData.data.code_commune
          && p.data.code_election === selectedElection.code_election
          && p.data.numero_tour === selectedElection.numero_tour
        ) return p;
        if(p.data.niveau_detail === 'bu'
          && p.data.numero_lieu === selectedBureauData.data.numero_lieu
          && p.data.nom_lieu === selectedCenterData.data.nom_lieu
          && p.data.code_commune === selectedCityData.data.code_commune
          && p.data.code_election === selectedElection.code_election
          && p.data.numero_tour === selectedElection.numero_tour
        ) return p;
        return null;
      });

      // var center = selectedCenterData?.data.geo_point ? [selectedCenterData.data.geo_point[1], selectedCenterData.data.geo_point[0]] : [-1.6777926, 48.117266]

      var projection = d3.geoMercator().fitSize([width / 2, height / 2], map )
      var translate = [width / 4, height / 4];
      path.projection(projection);

        deps.selectAll("path")
          .data(map.features)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("stroke-width" , 0 + "px")
          .style("stroke", function(d) {
            if (d.data) {
              return "white" ;
            } else if (!d.data) {
              return "white";
            } else {
              return 'transparent';
            }
          })
          .on("mouseover", function(d) {
            if (d.data) {
              let results = getCandidatsArray(d.data).sort((a, b) => a.nb_voix - b.nb_voix).reverse().filter( candidat => candidat.pourcentage > 5 );
              tooltip.transition()		
                .duration(200)		
                .style("opacity", .9);

              if (election === "Législatives") {
                tooltip.html( "<p class='bold name'><span class='text-gray'>"+ d.data.nom_circonscription + "</span><br/>Bureau " + d.data.numero_lieu +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                  .style("left", (d3.event.pageX) + "px")		
                  .style("top", (d3.event.pageY - 28) + "px");	
              } else {
                tooltip.html( "<p class='bold name'>Bureau "+ d.data.numero_lieu +"</p>" + results.map(candidat => "<div class='row'><p class='candidat'>" + candidat.name + "</p><p>" + candidat.pourcentage + " %</p> <p>" + candidat.nb_voix + " voix</p></div>" ).join('') )	
                  .style("left", (d3.event.pageX) + "px")		
                  .style("top", (d3.event.pageY - 28) + "px");	
              }

            }})			
          .on("mouseout", function(d) {		
            tooltip.transition()		
              .duration(500)		
              .style("opacity", 0);	
          })
          .style('opacity' , 0 )
          .style("fill" , "transparent")
          .transition()
          .duration(250)
          .style("fill" , function(d) {
            if (d.data) {
              return "#1D5993" ;
            } else if (!d.data) {
              return 'red';
            } else {
              return 'transparent';
            }
          }).style("opacity" , function(d) { 
            if (d.data) {
              return opacityByParticipation(d.data.pourcentage_participation);
            } else {
              return 0.1;
            }
          });

          svg.selectAll("g")
            .attr("transform", "translate(" + [width / 2, height / 2] + ") scale(0)")  
            .transition()
            .duration(750)
            .attr("transform", "translate(" + translate + ") scale(1)");

    }
  }

  return (
    <>
      <div ref={mapRef} id="map"></div>
    </>
  );
}
