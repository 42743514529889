import React from 'react';
import TitleNav from '../../../Partials/TitleNav/TitleNav';
import Candidats from '../../Candidats/Candidats';
import ProgressBar from '../../ProgressBar';

import styles from './MapResult.module.scss';

export default function MapResult({
  setSelectedCenterData,
  setSelectedBureauData,
  setSelectedCityData,
  name,
  displayCities,
  selectedCenterData,
  selectedBureauData,
  selectedCityData,
  results,
  activePanel,
  setActivePanel,
  electionProgress = null,
  appSize,
}) {
  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        {electionProgress &&  <ProgressBar electionProgress={electionProgress}/>}
      </div>
    {results &&
      <>
        {((selectedCityData && displayCities) || (selectedCenterData && !displayCities)) &&
          <div className={styles.nav}>
            <div className={styles.back}> 
              <TitleNav
                setSelectedCenterData={setSelectedCenterData}
                setSelectedBureauData={setSelectedBureauData}
                setSelectedCityData={setSelectedCityData}
                name={name}
                selectedCenterData={selectedCenterData}
                displayCities={displayCities}
                selectedBureauData={selectedBureauData}
                selectedCityData={selectedCityData}
                isMobile={true}
              />
            </div>
          </div>
        } 
        <div className={styles.result}>
        {selectedCityData
          && <Candidats
            appSize={appSize}
            results={results}
            isMobile={true}
            activePanel={activePanel}
            setActivePanel={setActivePanel}
          />
        }
        </div>
      </>
      }
    </div>
  )
}
