import React, { useRef, useState } from 'react'
import info from '../../../assets/images/info.svg';
import infoClose from '../../../assets/images/info-close.svg';
import home from '../../../assets/images/home.svg';
import shareIcon from '../../../assets/images/share.svg';

import Share from '../Share';

import styles from './DataHeader.module.scss';
import LegendParticipation from '../LegendParticipation/LegendParticipation';

export default function DataHeader({
  isInIframe,
  navBack,
  name,
  tourLabel,
  text,
  isDirect,
  isMobile,
  data,
}) {
  const [ textIsOpen, setTextIsOpen ] = useState();
  const [ shareIsActive , setShareIsActive ] = useState(false);
  const textRef = useRef();
  return (
    <>
    <Share
      setShareIsActive={setShareIsActive}
      shareIsActive={shareIsActive}
      />
    <div className={`${styles.container} ${isDirect ? styles.direct :''}`}>
      <div>
        <button
          className={styles.back}
          onClick={() => navBack()}
          >
          <img className={styles.home} src={home} alt="back-arrow"/>accueil
        </button>
      </div>
      <div className={styles["title-container"]}>
        <div className={styles.title}>
          <h1>{name}</h1>
        </div>
        <span>{tourLabel} </span>
        <button
          onClick={() => setTextIsOpen(!textIsOpen)}
          className={styles.toggle}>
          <img src={textIsOpen ? infoClose : info} alt="" />
        </button>
      </div>

        <div
          className={styles.text}
          style={{
            overflow: textIsOpen ? 'auto' : 'hidden' ,
            marginTop: textIsOpen ? 20 : 0,
            height: textIsOpen ? data && textRef.current.innerHeight : 0 ,
            opacity: textIsOpen ? 1 : 0
          }}
        >
        <div ref={textRef}>
          <p>
            {text.description}
          </p>
          {isMobile &&
            <LegendParticipation allDatas={data.records} />
          }
        </div>
      </div>
      {isInIframe() === false &&
        <button className="hide-mobile btn-share" onClick={() => setShareIsActive(!shareIsActive)} >
          <img src={shareIcon} alt="share"/> PARTAGER 
        </button>
      }
    </div>
    </>
  )
}
