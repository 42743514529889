import React from 'react';
import '../../styles/list.scss';
import ElectionRow from './ElectionRow';

export default function ElectionList({ selectedCenterData , selectedCityData, bureauxList , centersList, results }) {
  let list = centersList;
  if (selectedCenterData?.data) {
    list = bureauxList;
  }
  return (
    <div className="list-container slide-in-right">
      <div className="list">
      {list?.map((element , i ) => {
        const data = element.fields;
        return (
          <div key={i} className="list-item-row">
            <ElectionRow
              results={results}
              name={selectedCenterData?.data ? "Bureau " + data.numero_lieu :  data.nom_lieu}
              data={data}/>
          </div>
      )})}
      {!selectedCenterData && selectedCityData &&
        <div className="list-item-row">
          <ElectionRow
            results={results}
            name={selectedCityData?.data?.libelle_commune}
            data={selectedCityData?.data}/>
        </div>
      }
      </div>
    </div>
  );

}
